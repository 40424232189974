export class ItemTaxonomyId {
  // ITEM
  // atn::KINGDOM/PHYLUM/FAMILY/SPECIES/STRAIN
  static ITEM_TAXONOMY_REGEX =
    /atn::(?<kingdom>[\w-]+)\/(?<phylum>[\w-]+)\/(?<family>[\w-]+)\/(?<species>[\w-]+)\/?(?<strain>[\w-]+)?/;

  private static readonly TYPE_PREFIX = "atn";

  private constructor() {}

  /**
   * Will create a TaxonomyId object by using the input parameters.
   * @param kingdom The Kyngdom (software or hardware)
   * @param phylum The Phylum (the )
   * @param family The Family (normally, the developer or manufacturer)
   * @param species The Species (the product name)
   * @param strain The Strain (the product variant)
   * @returns The TaxonomyId object
   */
  public static Create(kingdom: string, phylum: string, family: string, species: string, strain: string | undefined) {
    const itemTaxonomyId = new ItemTaxonomyId();
    itemTaxonomyId._kingdom = kingdom;
    itemTaxonomyId._phylum = phylum;
    itemTaxonomyId._family = family;
    itemTaxonomyId._species = species;
    itemTaxonomyId._strain = strain;
    return itemTaxonomyId;
  }

  /**
   * Will create a TaxonomyId object by parsing a string. Can throw an Error is the string is invalid
   * @param taxonomyId The taxonomyId string to parse
   * @returns The TaxonomyId object
   */
  public static Parse(taxonomyId: string): ItemTaxonomyId {
    const itemTaxonomyId = new ItemTaxonomyId();
    const match = taxonomyId.match(ItemTaxonomyId.ITEM_TAXONOMY_REGEX);

    if (!match || !match.groups) {
      throw new Error("Invalid ItemTaxonomyId string");
    }

    itemTaxonomyId._kingdom = match.groups.kingdom;
    itemTaxonomyId._phylum = match.groups.phylum;
    itemTaxonomyId._family = match.groups.family;
    itemTaxonomyId._species = match.groups.species;
    itemTaxonomyId._strain = match.groups.strain;
    return itemTaxonomyId;
  }

  public static ParseWithoutTypePrefix(taxonomyId: string): ItemTaxonomyId {
    return this.Parse(`${ItemTaxonomyId.TYPE_PREFIX}::${taxonomyId}`);
  }

  // Properties
  protected _kingdom: string | undefined;
  get kingdom() {
    return this._kingdom;
  }

  protected _phylum: string | undefined;
  get phylum() {
    return this._phylum;
  }

  protected _family: string | undefined;
  get family() {
    return this._family;
  }

  protected _species: string | undefined;
  get species() {
    return this._species;
  }

  protected _strain: string | undefined;
  get strain() {
    return this._strain;
  }

  /**
   * Builds a string representation
   * @returns The TaxonomyId string that represents this object
   */
  public getTaxonomyId(): string {
    return `${ItemTaxonomyId.TYPE_PREFIX}::${this.getUrlSuffix()}`;
  }

  /**
   * Builds a string representation without the type prefix. Useful to use in URL and API calls
   * @returns The URL safe string
   */
  public getUrlSuffix(): string {
    let suffix: string = `${this._kingdom}/${this._phylum}/${this._family}/${this._species}`;

    // Strain
    if (this._strain) {
      suffix += "/";
      suffix += this._strain;
    }

    return suffix;
  }

  /**
   * Builds a string representation without the strain (SKU).
   * @returns The taxonomy ID string without the strain (SKU)
   */
  public getTaxonomyIdWithoutStrain(): string {
    return `${ItemTaxonomyId.TYPE_PREFIX}::${this._kingdom}/${this._phylum}/${this._family}/${this._species}`;
  }
}
