import "./style.scss";

import { Container, Row, View } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import React from "react";

import { getString } from "../../uistringlabels/uiStringUtils";
import { CatalogicalSearchBox } from "../CatalogicalSearchBox";

export const Hero = () => (
  <Container className="hero" isFullWidth={false} paddingHorizontal="S500" paddingTop="S700" paddingBottom="S700">
    <View>
      <Row>
        <Text data-cy="hero-title" color="neutral05" fontSize="T600" fontWeight="light">
          {getString("hero.title")}
        </Text>
      </Row>
      <Row margin={["S300", 0, 0, 0]}>
        <CatalogicalSearchBox width="50%"></CatalogicalSearchBox>
      </Row>
    </View>
  </Container>
);
