import { MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { useAppSelector } from "common/hooks";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addMessageAction, removeMessageAction, selectMessages } from "./reducer";

export interface MessageBannerProps {
  /**
   * Unique identifier used to correctly dissmis the banners
   */
  id: string;

  /**
   * Text to be displayed on the banner
   */
  message: string;

  /**
   * Type of the banner, the field affects the colors and icon used in the banner
   */
  type: MessageBannerType;
}

export interface useMessageBannersBoxResult {
  /**
   * Queue of props that can be used to render a banner message
   */
  messages: Array<MessageBannerProps>;

  /**
   * Function to add a new error banner message
   */
  addErrorBanner: (message: string, error?: any) => void;

  /**
   * Function to remove a message from the queue
   */
  removeMessage: (id: string) => void;
}

/**
 * Custom hook to access the message banner box component
 * @returns functions to add add ErrorBanner, removeMessages and a reference to the messages in queue
 */
export function useMessageBannersBox(): useMessageBannersBoxResult {
  const dispatch = useDispatch();
  const messages = useAppSelector(selectMessages);

  const addErrorBanner = (message: string, _error?: any) => {
    dispatch(addMessageAction({ id: uuidv4(), message, type: MessageBannerType.Error }));
  };

  const removeMessage = (id: string) => {
    dispatch(removeMessageAction(id));
  };

  return {
    addErrorBanner,
    removeMessage,
    messages,
  };
}
