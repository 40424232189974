import { Col, Container, Row } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Text } from "@amzn/stencil-react-components/text";
import { TopBar } from "common/components";
import { MyOrdersTable } from "common/components/MyOrdersTable";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC } from "react";

export const OrdersPage: FC = () => {
  return (
    <PageContainer paddingHorizontal={0} paddingTop={0} isFullWidth={true}>
      <TopBar>
        <Text fontSize="T300" color="neutral0">
          {getString("myOrders.topBar")}
        </Text>
      </TopBar>
      <Container isFullWidth={false}>
        <Col gridGap="S200" width={"100%"}>
          <Row padding="S300" gridGap="S400" width={"100%"}>
            <Col gridGap="S200" width={"100%"}>
              <MyOrdersTable />
            </Col>
          </Row>
        </Col>
      </Container>
    </PageContainer>
  );
};
