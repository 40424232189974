import { Badge } from "@amzn/stencil-react-components/badge";
import { PageHeaderLink } from "@amzn/stencil-react-components/page";
import { getString } from "common/uistringlabels/uiStringUtils";
import { reduxStore } from "init/providers";
import React, { FunctionComponent, useEffect } from "react";
import { Provider } from "react-redux";
import { useHistory } from "react-router-dom";

import { usePendingOrdersLink } from "./hook";

export const ApprovalRequestsLink: FunctionComponent = () => {
  const { isManager, pendingOrdersCount, refreshPendingOrderCount } = usePendingOrdersLink();
  const history = useHistory();

  /* istanbul ignore next */
  const onOrdersApprovalClick = (e: any) => {
    e.preventDefault();
    history.push("/ordersApproval");
  };

  useEffect(() => {
    refreshPendingOrderCount().catch(console.error);
  }, [pendingOrdersCount]);

  if (!isManager) {
    return <></>;
  }

  return (
    <Provider store={reduxStore}>
      <PageHeaderLink
        key="3"
        href="/ordersApproval"
        onClick={onOrdersApprovalClick}
        data-cy="approval-requests-link"
        data-testid="approval-requests-link"
        className="header-link approval-requests-link"
      >
        <Badge value={pendingOrdersCount} />
        {getString("header.approvalRequests")}
      </PageHeaderLink>
    </Provider>
  );
};
