import "./style.scss";
import "./style.scss";

import { Col } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import React, { FC, useState } from "react";
import { pageLimit } from "view/Page/CatalogicalSearchResults";

import { useSearchCatalogicalItems } from "../CatalogicalItemsList/hook";

export interface FacetedFilterProps {
  title: string;
  allOptionText: string;
  type: "categories" | "os";
}

const CatalogicalFacetedFilter: FC<FacetedFilterProps> = ({ title, allOptionText, type }) => {
  const { searchCatalogItems, facets, searchParameters } = useSearchCatalogicalItems();

  const [selectedFacet, setSelectedFacet] = useState<string>("");

  /* istanbul ignore next */
  const handleFacetClick = (value: string) => {
    setSelectedFacet(value);
    const filter = {
      ...JSON.parse(searchParameters?.filter || "{}"),
      [type]: value,
    };
    const parameters = {
      keywords: searchParameters?.keywords || "",
      offset: 0,
      limit: pageLimit,
      filter: JSON.stringify(filter),
      fuzzy: true,
      sortBy: "name",
      sortDirection: "asc",
    };
    searchCatalogItems(parameters, false, true);
  };

  /* istanbul ignore next */
  const handleAllOptionsClick = () => {
    setSelectedFacet("");
    const currentFilters = JSON.parse(searchParameters?.filter || "{}");
    delete currentFilters[type];
    const parameters = {
      keywords: searchParameters?.keywords || "",
      offset: 0,
      limit: pageLimit,
      filter: JSON.stringify(currentFilters),
      fuzzy: true,
      sortBy: "name",
      sortDirection: "asc",
    };
    searchCatalogItems(parameters, false, true);
  };

  /* istanbul ignore next */
  const facetsToDisplay = facets?.[type] || [];

  const facetsMapping = facetsToDisplay.map((facet) => (
    <Link
      textDecoration="none"
      key={facet}
      onClick={() => handleFacetClick(facet)}
      color="neutral90"
      href="#"
      margin={["S300", 0, 0, 0]}
      padding={[0, 0, 0, "S500"]}
      fontWeight={selectedFacet === facet ? "bold" : "regular"}
    >
      {facet}
    </Link>
  ));

  return (
    <Col padding={[0, 0, "S700", 0]}>
      <Text color="neutral70" margin={["S300", 0, 0, "S500"]} data-cy={`facet-filter-title`}>
        {title}
      </Text>
      <Link
        textDecoration="none"
        fontWeight={selectedFacet === "" ? "bold" : "regular"}
        href="#"
        color="neutral90"
        margin={["S300", 0, 0, 0]}
        padding={[0, 0, 0, "S500"]}
        onClick={handleAllOptionsClick}
      >
        {allOptionText}
      </Link>
      {facetsMapping}
    </Col>
  );
};

export default CatalogicalFacetedFilter;
