import "./style.scss";

import { IconAlertTriangleFill } from "@amzn/stencil-react-components/icons";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { setUserId } from "@snowplow/browser-tracker";
import { IS_MAINTENANCE_MODE_ENABLED, PERMISSIONS } from "config";
import { UserPermissions } from "lib/services";
import { Fragment, FunctionComponent, useState } from "react";
import React, { useEffect } from "react";
import { default as LoadingOverlay } from "react-loading-overlay";
import MaintenancePage from "view/Page/Maintenance";

// istanbul ignore next
LoadingOverlay.propTypes = undefined;
/**
 * Shows a loading spinner during initial Authentication/Authorization.
 */
const AuthLoading: FunctionComponent = ({ children }) => {
  const [authFailed, setAuthFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined as Error | undefined);
  const [showMaintenancePage, setShowMaintenancePage] = useState(false);
  // const mounted = useMounted();

  const onUserAuthorized = () => {
    if (IS_MAINTENANCE_MODE_ENABLED) {
      if (UserPermissions.current.hasPermission(PERMISSIONS.BRAVE_HEART)) {
        console.info("Maintenance mode is in display for non bravehearts members.");
      } else {
        setShowMaintenancePage(true);
      }
    }
  };

  useEffect(() => {
    if (!UserPermissions.current.hasAuthorized && !authFailed) {
      UserPermissions.current
        .authorize()
        .catch((err) => {
          // if (mounted.current) {
          //   setError(err);
          // }
          setError(err);
          setAuthFailed(true);
        })
        .finally(() => {
          // if (mounted.current) {
          //   setLoading(false);
          // }

          setUserId(UserPermissions.current.username);
          onUserAuthorized();
          setLoading(false);
        });
    }
  });

  if (error) {
    return (
      <MessageBanner
        icon={<IconAlertTriangleFill size={16} title="Exclamation triangle" />}
        isDismissible={false}
        titleText="Authentication or Authorization failed"
        type={MessageBannerType.Error}
      >
        {error.message}
      </MessageBanner>
    );
  }

  if (loading) {
    return (
      <LoadingOverlay
        active={loading}
        className="auth-loading"
        fadeSpeed={0}
        spinner
        text={
          <Fragment>
            <p>Authenticating and authorizing user...</p>
          </Fragment>
        }
      />
    );
  }

  const display = showMaintenancePage ? <MaintenancePage /> : children;

  return <Fragment>{display}</Fragment>;
};

export default AuthLoading;
