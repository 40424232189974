import { configureStore } from "@reduxjs/toolkit";
import React, { FunctionComponent } from "react";
import { Provider } from "react-redux";
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { getPersistedReducer } from "./redux-persist-helper";

export const reduxStore = configureStore({
  reducer: getPersistedReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

/**
 * Create a store using redux-persist.
 * @see: https://github.com/rt2zz/redux-persist
 */
const persistedStore: any = persistStore(reduxStore);

export const ReduxProvider: FunctionComponent = ({ children }) => {
  return (
    <Provider store={reduxStore}>
      <PersistGate persistor={persistedStore}>{children}</PersistGate>
    </Provider>
  );
};
