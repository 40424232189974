/* istanbul ignore file */

import { getConfigByEnv } from "config";
import { UserPermissions } from "lib/services";

/**
 * Provides utility helper functions.
 */
export class UtilHelper {
  /**
   * Returs a promise that resolves in the specified number of milliseconds.
   * @param milliseconds Number of milliseconds to wait to resolve the promise.
   * @returns A promise that resolves in the specified number of milliseconds.
   */
  public static async sleep(milliseconds: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  /**
   * Returns a single configuration file for the customer feedback module based on its name and env settings
   * @param moduleName Module name to load the speficic set of questions
   * @returns Unified configuration settings for the cfm widget settings
   */
  public static getCustomerFeedbackConfiguration(moduleName: string): any {
    const baseConfigSettings = getConfigByEnv().cfmConfig;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const baseConfigFile = require(`../../configuration/CustomerFeedbackModule/${baseConfigSettings}`);
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const moduleConfigFile = require(`../../configuration/CustomerFeedbackModule/${moduleName}.config.json`);
    return { ...baseConfigFile, ...moduleConfigFile, username: UserPermissions.current.username };
  }
}
