/* istanbul ignore file */
import { combineReducers } from "@reduxjs/toolkit";
import { MessageBannerBoxState, messageBannersBoxReducer } from "common/components/MessageBannersBox/reducer";

import { catalogItemsReducer, CatalogItemsState } from "./components/CatalogItemsList";

export interface OldAppState {
  /**
   * State for the catalog items data
   */
  catalogItems: CatalogItemsState;
  /**
   * State for the message banners box data
   */
  messageBannersBox: MessageBannerBoxState;
}

const oldAppReducer = combineReducers<OldAppState>({
  catalogItems: catalogItemsReducer,
  messageBannersBox: messageBannersBoxReducer,
});

export default oldAppReducer;
