import { SearchRequestContent } from "common/types";

export class SearchHelper {
  /**
   * Create query params based on search parameters
   * @param parameters Search parameters
   * @returns string with query params
   */
  public static getQueryParams(parameters: SearchRequestContent): string {
    const searchParams = new URLSearchParams();

    if (parameters.text) {
      searchParams.append("text", `${parameters.text}`);
    }

    if (parameters.categories && parameters.categories.length > 0)
      parameters.categories.forEach((category) => searchParams.append("categories[]", category));

    if (parameters.tags && parameters.tags.length > 0)
      parameters.tags.forEach((tag) => searchParams.append("tags[]", tag));

    if (parameters.pageSize) {
      searchParams.append("pageSize", `${parameters.pageSize}`);
    }

    if (parameters.nextToken) {
      searchParams.append("nextToken", `${parameters.nextToken}`);
    }

    if (parameters.vendors && parameters.vendors.length > 0)
      parameters.vendors.forEach((vendor) => searchParams.append("vendors[]", vendor));

    if (parameters.includeFacets !== undefined) {
      searchParams.append("includeFacets", `${parameters.includeFacets}`);
    }

    return searchParams.toString();
  }
}
