import { Order } from "@amzn/ito-client";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Input } from "@amzn/stencil-react-components/form";
import { Col, Row, Spacer } from "@amzn/stencil-react-components/layout";
import { ModalContent } from "@amzn/stencil-react-components/modal";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Label, Text } from "@amzn/stencil-react-components/text";
import { usePendingOrdersLink } from "common/components/ApprovalRequestsLink/hook";
import { DENIED } from "common/types/orders-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import { ITOOrdersService } from "lib/services";
import React, { Dispatch, SetStateAction, useState } from "react";
import { FunctionComponent } from "react";

export interface DeclineOrderModalProps {
  order?: Order;
  close: (shouldReload: boolean) => void;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  setErrorMessage: Dispatch<SetStateAction<string>>;
}

export const DeclineOrderModal: FunctionComponent<DeclineOrderModalProps> = ({
  order,
  close,
  setSuccessMessage,
  setErrorMessage,
}: DeclineOrderModalProps) => {
  const [reason, setReason] = useState("");
  const { refreshPendingOrderCount } = usePendingOrdersLink();

  /* istanbul ignore next */
  const onReasonChange = (event: any) => {
    setReason(event.target.value);
  };

  const [isLoading, setLoading] = useState(false);
  const { orderId, requester, requested, display } = order!;
  const amazonianDisplayName = `${requester?.basicInfo?.firstName} ${requester?.basicInfo?.lastName} (${requested?.by}@)`;

  /* istanbul ignore next */
  const declineOrder = async () => {
    try {
      setLoading(true);
      const ito = new ITOOrdersService();
      const csrfToken = await ito.GetSecurityToken();
      const postCommentResult = await ito.PostComment(orderId!, reason, csrfToken);
      const updateStatusResult: Order = await ito.UpdateStatus(orderId!, DENIED, csrfToken);
      if (postCommentResult && updateStatusResult) {
        setSuccessMessage(
          getString("myOrdersApproval.declinedSuccessMessage", { name: amazonianDisplayName, software: display })
        );
      } else {
        setErrorMessage("Error");
      }
    } catch (e) {
      setErrorMessage(getString("errors.general"));
    } finally {
      await refreshPendingOrderCount();
      close(true);
    }
  };

  return (
    <ModalContent
      titleText={getString("myOrdersApproval.declineHeader")}
      buttons={[
        <Button
          key="submitOrder-close"
          variant={ButtonVariant.Tertiary}
          onClick={() => {
            close(false);
          }}
          disabled={isLoading}
        >
          Cancel
        </Button>,
        <Button
          key="decline-order-submit"
          data-testid="decline-order-submit"
          data-cy="decline-order-button"
          variant={ButtonVariant.Primary}
          onClick={declineOrder}
          disabled={isLoading || !reason}
        >
          {getString("myOrdersApproval.decline")} {isLoading && <Spinner size={SpinnerSize.Small}></Spinner>}
        </Button>,
      ]}
    >
      <>
        <Row>
          <Col gridGap="S200" alignSelf="flex-start">
            <Text color="neutral90" dataTestId="decline-instructions">
              {getString("myOrdersApproval.instructions")}
            </Text>
          </Col>
        </Row>
        <Spacer height={24}></Spacer>
        <Row>
          <Col gridGap="S200" alignSelf="flex-start">
            <Label fontWeight={"bold"} htmlFor="requester-name">
              {getString("myOrdersApproval.requester")}
            </Label>
            <Text id="requester-name" color="neutral90">
              {amazonianDisplayName}
            </Text>
          </Col>
          <Spacer width={24}></Spacer>
          <Col gridGap="S200" alignSelf="flex-end">
            <Label fontWeight={"bold"} htmlFor="display-name">
              {getString("myOrdersApproval.requestedSoftware")}
            </Label>
            <Text id="display-name" color="neutral90">
              {display}
            </Text>
          </Col>
        </Row>
        <Spacer height={24}></Spacer>
        <Row id="last-row">
          <Col gridGap="S200" width={"100%"}>
            <Label fontWeight={"bold"} htmlFor="reason-input">
              {getString("myOrdersApproval.reason")}
            </Label>
            <Input id="reason-input" dataTestId="reason-input" value={reason} onChange={onReasonChange} />
          </Col>
        </Row>
      </>
    </ModalContent>
  );
};
