import "./style.scss";

import { Col } from "@amzn/stencil-react-components/layout";
import { MessageBanner, MessageBannerLink } from "@amzn/stencil-react-components/message-banner";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent } from "react";

import { MessageBannerProps, useMessageBannersBox } from "./hook";
const MAX_MESSAGES_IN_SCREEN = 5;

/**
 * Component to show message banners
 */
export const MessageBannersBox: FunctionComponent = () => {
  const { messages, removeMessage } = useMessageBannersBox();
  const visibleMessages = messages.slice(0, MAX_MESSAGES_IN_SCREEN);

  const onBannerDissmised = (id: string) => {
    removeMessage(id);
  };

  return (
    <Col className="message-banners-box-col">
      {visibleMessages.map((props: MessageBannerProps) => {
        return (
          <MessageBanner
            isDismissible
            key={`${props.id}`}
            type={props.type}
            onDismissed={() => onBannerDissmised(props.id!)}
          >
            {props.message}
            {getString("errors.persistmessage")}
            <MessageBannerLink
              href="https://t.corp.amazon.com/create/templates/dfc15985-f663-4f58-8cf7-a625f09d0565"
              target={"_blank"}
            >
              {getString("errors.ctitext")}
            </MessageBannerLink>
          </MessageBanner>
        );
      })}
    </Col>
  );
};
