import { IS_V1 } from "config";
import stringify from "json-stringify-safe";
import localForage from "localforage";
import LZ from "lz-string";
import appReducer from "modules/app-reducer";
import oldAppReducer from "modules/old-app-reducer";
import { createTransform, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const STORE_NAME = "ITMarketplace";

/**
 * Function which transforms state before and after redux-persist stores the state.
 * Implements lz-string to LZ compress the text before storage and decompress after storage.
 * @see: https://github.com/pieroxy/lz-string
 */
const lzCompressState = createTransform(
  (inboundState) => LZ.compressToUTF16(stringify(inboundState)),
  (outboundState) => {
    try {
      return JSON.parse(LZ.decompressFromUTF16(outboundState) || "");
    } catch (err) {
      console.error("Cannot retrieve state: error while decompressing state", err);
      return null;
    }
  }
);

/**
 * Config for localForage.
 * @see: https://localforage.github.io/localForage/
 */
const reduxPersistDbConfig: LocalForageOptions = {
  storeName: STORE_NAME,
  name: STORE_NAME,
  description: "Storage for Redux-Persist.",
};

/**
 * Creates an instance of localForage for Redux-Persist.
 * @see: https://localforage.github.io/localForage/
 */
const reduxPersistDb = localForage.createInstance(reduxPersistDbConfig);

const isV1CatalogServiceEnabled = (): boolean => IS_V1 === "true";

const getReducer = () => {
  if (isV1CatalogServiceEnabled()) {
    return oldAppReducer;
  }
  return appReducer;
};

/**
 * Gets an instance of a persistReducer used for creating the Redux store.
 * @see: https://github.com/rt2zz/redux-persist
 */
export const getPersistedReducer: any = () =>
  persistReducer(
    {
      key: STORE_NAME,
      storage: reduxPersistDb,
      stateReconciler: autoMergeLevel2,
      transforms: [lzCompressState],
      // eslint-disable-next-line inclusive-language/use-inclusive-words
      whitelist: [STORE_NAME],
    },
    getReducer() as any
  );
