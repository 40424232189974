import "./learningModule.scss";

import { Col, Container, Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import {
  CustomerFeedback,
  CustomerFeedbackAlignment,
  CustomerFeedbackIconPosition,
} from "common/components/CustomerFeedback";
import { getString } from "common/uistringlabels/uiStringUtils";
import { ITMP_V1_URL } from "config";
import React, { FunctionComponent } from "react";

export const LearningModule: FunctionComponent = () => (
  <Container>
    <Row>
      <h2 className="explore-title" data-cy="explore-title">
        <Text fontSize={"T400"} fontWeight={500} color="neutral70">
          {getString("home.exploreItmp")}
        </Text>
      </h2>
    </Row>
    <Row>
      <Col padding={[0, "S300", 0, 0]} width="33%">
        <Text fontSize="T200" margin={[0, 0, "S300", 0]} fontWeight="bold" color="neutral90" data-cy="find-title">
          {getString("home.findTitle")}
        </Text>
        <Text fontSize="T200" margin={[0, 0, "S300", 0]} color="neutral90">
          {getString("home.findContent")}
        </Text>
        <Text fontSize="T200" color="neutral90">
          {getString("home.requestContent")}
          <Link
            className="request-link"
            href={getString("externalLinks.uniqueRequests")}
            target="_blank"
            data-cy="request-link"
          >
            {getString("home.requestLinkText")}
          </Link>
        </Text>
      </Col>
      <Col padding={[0, "S300", 0, "S300"]} width="34%">
        <Text
          fontSize="T200"
          margin={[0, 0, "S300", 0]}
          fontWeight="bold"
          color="neutral90"
          data-cy="place-order-title"
        >
          {getString("home.placeYourOrderTitle")}
        </Text>
        <Text fontSize="T200" color="neutral90">
          {getString("home.placeYourOrderContent")}
          <b>{getString("home.orderNow")}</b>
          {getString("home.placeYourOrderFollowUpContent")}
        </Text>
      </Col>
      <Col padding={[0, 0, 0, "S300"]} width="33%">
        <Text
          fontSize="T200"
          margin={[0, 0, "S300", 0]}
          fontWeight="bold"
          color="neutral90"
          data-cy="get-support-title"
        >
          {getString("home.getSupportTitle")}
        </Text>
        <Text fontSize="T200" margin={[0, 0, "S300", 0]} color="neutral90">
          {getString("home.getSupportVisit")}
          <Link className="get-support-link" href={`${ITMP_V1_URL}/help`} target="_blank" data-cy="get-support-link">
            {getString("home.getSupportLinkText")}
          </Link>
          {getString("home.getSupportContent")}
        </Text>
        <Text fontSize="T200" color="neutral90">
          {getString("home.getSupportFollowUpContent")}
        </Text>
      </Col>
    </Row>
    <Row padding={[0, 0, "S500", 0]}>
      <Col width="100%">
        <CustomerFeedback
          moduleName="exploreItmp"
          alignment={CustomerFeedbackAlignment.Right}
          position={CustomerFeedbackIconPosition.Left}
        />
      </Col>
    </Row>
  </Container>
);
