import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IS_V1 } from "config";
import { produce } from "immer";
import { AppState } from "modules/app-reducer";
import { OldAppState } from "modules/old-app-reducer";

import { MessageBannerProps } from "./hook";

export interface MessageBannerBoxState {
  messages: Array<MessageBannerProps>;
}

const defaultState: MessageBannerBoxState = {
  messages: [],
};

/**
 * Contains the redux reducers logic to update the state
 */
const messageBannersBoxSlice = createSlice({
  name: "MessageBannersBoxSlice",
  initialState: defaultState,
  reducers: {
    addMessageAction(state, action: PayloadAction<MessageBannerProps>) {
      const nextState = onAddMessage(state, action);
      state.messages = nextState.messages;
    },

    removeMessageAction(state, action: PayloadAction<string>) {
      const nextState = onRemoveMessage(state, action);
      state.messages = nextState.messages;
    },
  },
});

const onAddMessage = (state: MessageBannerBoxState, action: PayloadAction<MessageBannerProps>) => {
  return produce(state, (draft: MessageBannerBoxState) => {
    draft.messages = [...draft.messages, action.payload];
    return draft;
  });
};

const onRemoveMessage = (state: MessageBannerBoxState, action: PayloadAction<string>) => {
  return produce(state, (draft: MessageBannerBoxState) => {
    const removeId = action.payload;
    draft.messages = draft.messages.filter((n) => n.id != removeId);
    return draft;
  });
};

const isV1CatalogServiceEnabled = (): boolean => IS_V1 === "true";

// Type for the state expected by selectMessages, which can be either AppState or OldAppState
type StateType = AppState | OldAppState;

export const selectMessages = (state: StateType): Array<MessageBannerProps> => {
  if (isV1CatalogServiceEnabled()) {
    return (state as OldAppState).messageBannersBox.messages;
  } else {
    return (state as AppState).messageBannersBox.messages;
  }
};

export const messageBannersBoxReducer = messageBannersBoxSlice.reducer;
export const { addMessageAction, removeMessageAction } = messageBannersBoxSlice.actions;
