import "common/styles/style";

import { StencilProvider } from "@amzn/stencil-react-components/context";
import AuthLoading from "common/components/AuthLoading";
import { initAnalytics } from "common/helpers/analytics-helper";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "view/Routes";

import { Provider } from "./init/providers/Provider";

initAnalytics();

export const AppWithStencil = () => (
  <StencilProvider mode="dense">
    <Provider>
      <AuthLoading>
        <Routes />
      </AuthLoading>
    </Provider>
  </StencilProvider>
);

ReactDOM.render(<AppWithStencil />, document.getElementById("app"));
