import "./searchResults.scss";

import { Card } from "@amzn/stencil-react-components/card";
import { useResizeObserver } from "@amzn/stencil-react-components/hooks";
import { Col, Hr, Row, View } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Text } from "@amzn/stencil-react-components/text";
import { CustomerFeedback, CustomerFeedbackAlignment, CustomerFeedbackIconPosition, TopBar } from "common/components";
import { NoResultsFound } from "common/components/NoResultsFound";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { ITMP_V1_URL } from "../../../config";
import CatalogicalFacetedFilter from "../../../modules/components/CatalogicalFacetedFilter/index";
import { useSearchCatalogicalItems } from "../../../modules/components/CatalogicalItemsList/hook";
import CatalogicalItemsList from "../../../modules/components/CatalogicalItemsList/index";

export const pageLimit = 500;

const CatalogicalSearchResults: FC = () => {
  const location = useLocation();
  const [cfmStyle, setCfmStyle] = useState("cfm-scroll");
  const { ref } = useResizeObserver<HTMLDivElement>();

  /* istanbul ignore next */
  const queryValue = new URLSearchParams(location.search).get("query") || "";

  const { isLoading, searchCatalogItems, totalCount, nextToken, searchParameters, isLoadingNextItems, searchNext } =
    useSearchCatalogicalItems();

  /* istanbul ignore next */
  useEffect(() => {
    if (queryValue) {
      searchCatalogItems(
        {
          keywords: queryValue,
          offset: 0,
          limit: pageLimit,
          filter: "",
          fuzzy: true,
          sortBy: "name",
          sortDirection: "asc",
        },
        true,
        true
      );
    }
  }, [queryValue]);

  /* istanbul ignore next */
  const onLoadMore = () => {
    if (nextToken && !isLoadingNextItems && !isLoading) {
      searchNext({ ...searchParameters, offset: nextToken, limit: pageLimit });
    }
  };

  /* istanbul ignore next */
  const shouldLoadMore = () => {
    if (!ref.current) {
      return false;
    }
    return !hasVisibleScroll();
  };

  /* istanbul ignore next */
  const hasVisibleScroll = () => {
    if (ref?.current) {
      return ref.current.clientHeight < ref.current.scrollHeight;
    }

    return false;
  };

  /* istanbul ignore next */
  useLayoutEffect(() => {
    hasVisibleScroll() ? setCfmStyle("cfm-scroll") : setCfmStyle("cfm-no-scroll");
  }, [isLoading, hasVisibleScroll(), ref]);

  if (isLoading) {
    return (
      <View padding="S700">
        <Spinner size={SpinnerSize.Medium} />
      </View>
    );
  }

  /* istanbul ignore next */
  const resultText =
    totalCount === 0
      ? getString("noResults.searchResultText", { text: queryValue })
      : getString("catalogItemsList.resultsMessage", { totalCount, text: queryValue });

  return (
    <View className="search-results-container">
      <Row>
        <TopBar>
          <Text fontSize="T300" color="neutral0">
            {resultText}
          </Text>
        </TopBar>
      </Row>

      {totalCount === 0 ? (
        <NoResultsFound searchTerm={queryValue} />
      ) : (
        <Row width="100%" height="100%">
          <Col width={297} height="100%" backgroundColor="neutral05" className="facets-column">
            <Col width={297} className="search-results-column" height="calc(100% - 110px)">
              <CatalogicalFacetedFilter
                title={getString("categoryFilter.categoryTitle")}
                allOptionText={getString("categoryFilter.allCategories")}
                type="categories"
              />
              <CatalogicalFacetedFilter
                title={getString("categoryFilter.osTitle")}
                allOptionText={getString("categoryFilter.allOperatingSystems")}
                type="os"
              />
            </Col>
            <Col width={297} padding={[0, 0, "S400", 0]} height="110px" justifyContent="flex-end">
              <Col width="70%" margin={["S500", 0, 0, "S500"]}>
                <Hr size="wide" color="neutral30" />
              </Col>

              <Text color="neutral70" margin={["S300", 0, 0, "S500"]} fontSize="T14">
                {getString("submitSoftwareRequest.header")}
              </Text>
              <Link
                textDecoration="none"
                fontWeight="bold"
                href={`${ITMP_V1_URL}/unique_requests`}
                color="primary80"
                margin={["S300", 0, 0, 0]}
                padding={[0, 0, 0, "S500"]}
                fontSize="T14"
                data-cy="submit-request-link"
              >
                {getString("submitSoftwareRequest.link")}
              </Link>
            </Col>
          </Col>

          <Col id="catalog-items-list-container" className="search-results-column" ref={ref}>
            <CatalogicalItemsList
              scrollableTarget="catalog-items-list-container"
              onSearchNext={onLoadMore}
              shouldLoadMore={shouldLoadMore}
            />
          </Col>
        </Row>
      )}
      <Card
        className={`cfm-general ${cfmStyle}`}
        data-testid="cfm-card"
        isElevated={true}
        border={"1px solid neutral50"}
        style={{ position: "absolute", top: "55%" }}
      >
        <CustomerFeedback
          moduleName="searchResults"
          alignment={CustomerFeedbackAlignment.Centered}
          position={CustomerFeedbackIconPosition.Bottom}
        />
      </Card>
    </View>
  );
};

export default CatalogicalSearchResults;
