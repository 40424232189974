import { PERMISSIONS } from "../../../config";
import { UserPermissions } from "../user-permissions";
import { IOrdersService } from "./i-orders-service";
import { ITMPv1OrdersService } from "./itmpv1-orders-service";
import { ITOOrdersService } from "./ito-orders-service";

export class OrdersServiceFactory {
  public static getInstance(itemTx: string): IOrdersService {
    UserPermissions.current.authorize();

    // Check if config allows
    if (UserPermissions.current.hasPermission(PERMISSIONS.USE_V2)) {
      return new ITOOrdersService();
    } else {
      return new ITMPv1OrdersService();
    }
  }

  public static isITO(): boolean {
    return UserPermissions.current.hasPermission(PERMISSIONS.USE_V2);
  }
}
