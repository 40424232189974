import { Order } from "@amzn/ito-client";
import { ITOOrdersService } from "lib/services";
import { useEffect, useState } from "react";

export interface useOrdersTableResult {
  orders: Order[];
  isLoading: boolean;
}

/* istanbul ignore next */
export function useOrdersTable(): useOrdersTableResult {
  const ito = new ITOOrdersService();
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    const result = await ito.GetOrders_My("", 20, undefined);
    setOrders(result.orders!);
    setLoading(false);
  };

  return {
    orders,
    isLoading,
  };
}
