import "./style.scss";

import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Checkbox, TextArea } from "@amzn/stencil-react-components/form";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { MessageBanner } from "@amzn/stencil-react-components/message-banner";
import { ModalContent } from "@amzn/stencil-react-components/modal";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Label, Text } from "@amzn/stencil-react-components/text";
import { CreateOrderRequestContent } from "common/types";
import { MessageStruct } from "common/types/ui-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent, useState } from "react";

export interface SubmitUnsanctionedOrderModalProps {
  taxonomyId: string;
  submitOrderHandler: (request: CreateOrderRequestContent) => void;
  close: () => void;
  csrfTokenIsLoading: boolean;
  isLoadingCreate: boolean;
  messages?: MessageStruct[];
}

/**
 * Reusable component to submit a unsanctioned order
 */

export const SubmitUnsanctionedOrderModal: FunctionComponent<SubmitUnsanctionedOrderModalProps> = ({
  submitOrderHandler,
  close,
  csrfTokenIsLoading,
  isLoadingCreate,
  messages,
  taxonomyId,
}: SubmitUnsanctionedOrderModalProps) => {
  const [confirmIsChecked, setConfirmIsChecked] = useState(false);
  const [securityTicket, setSecurityTicket] = useState<undefined | string>(undefined);

  const submitOrder = async () => {
    const request: CreateOrderRequestContent = {
      items: {
        [taxonomyId!]: { quantity: 1, details: { softwareOrderDetails: { securityTicketID: securityTicket! } } },
      },
    };
    await submitOrderHandler(request);
  };

  /* istanbul ignore next */
  const canClickSubmmit = () => {
    if (!confirmIsChecked || isLoadingCreate) return false;

    return securityTicket != undefined && securityTicket.trim();
  };

  const selectModalBodyComponent = () => {
    if (csrfTokenIsLoading) {
      return <Spinner size={SpinnerSize.Medium} fr={undefined} />;
    }

    return (
      <Col gridGap="S400">
        <Col gridGap="S200">
          <Label htmlFor="ticket-text-area">{getString("submitUnsanctionOrder.textAreaLabel")}</Label>
          <TextArea
            id="ticket-text-area"
            placeholder={getString("submitUnsanctionOrder.textAreaPlaceHolder")}
            value={securityTicket}
            onChange={(e) => setSecurityTicket(e.target.value)}
            data-cy="ticket-text-area"
          />
        </Col>
      </Col>
    );
  };

  const modalBodyComponent = selectModalBodyComponent();

  // To display error messages
  let messagesDisplay = <Text></Text>;
  if (messages && messages.length > 0) {
    const listItems = messages.map((d) => (
      <MessageBanner key={d.message} type={d.type}>
        {d.message}
      </MessageBanner>
    ));
    messagesDisplay = (
      <div>
        <hr />
        {listItems}
      </div>
    );
  }

  return (
    <ModalContent
      titleText={getString("submitUnsanctionOrder.modalTitle")}
      buttons={[
        <Button key="submitOrder-close" variant={ButtonVariant.Tertiary} onClick={close}>
          Cancel
        </Button>,
        <Button
          key="submitOrder-submit"
          data-testid="submitOrder-submit"
          data-cy="submit-order-button"
          variant={ButtonVariant.Primary}
          disabled={!canClickSubmmit()}
          onClick={submitOrder}
        >
          Submit
          {isLoadingCreate ? <Spinner size={SpinnerSize.Small} fr={undefined} /> : null}
        </Button>,
      ]}
    >
      <Col gridGap="S400">
        {modalBodyComponent}
        <Col gridGap="S200">
          <Row gridGap="S200">
            <Checkbox
              id="checkReadAcceptableUsePolicy"
              data-cy="read-acceptable-use-policy-checkbox"
              checked={confirmIsChecked}
              onChange={(e: any) => setConfirmIsChecked(e.target.checked)}
            />
            <Label htmlFor="checkReadAcceptableUsePolicy">{getString("submitUnsanctionOrder.acceptConditions")}</Label>
          </Row>
        </Col>
        <Col gridGap="S200" alignSelf="flex-start">
          <Text fontSize="T100" color="neutral70">
            {getString("submitUnsanctionOrder.requiredField")}
          </Text>
        </Col>
        {messagesDisplay}
      </Col>
    </ModalContent>
  );
};
