import { Col, View } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Text } from "@amzn/stencil-react-components/text";
import React, { FC, Fragment } from "react";

const MaintenancePage: FC = () => {
  return (
    <PageContainer backgroundColor="neutral05" height="100%" width="100%" alignItems="flex-start" display="flex">
      <View alignItems="center" display="flex" height="300px" justifyContent="center" alignSelf="center" width="100%">
        <Col gridGap="S400" alignItems="center">
          <MaintenancePageContent />
        </Col>
      </View>
    </PageContainer>
  );
};

export default MaintenancePage;

const MaintenancePageContent = () => {
  return (
    <Fragment>
      <Text fontSize="T600" fontWeight="regular">
        We&apos;re currently down for maintenance.
      </Text>
      <Text fontSize="T500" fontWeight="regular">
        Please come back later.
      </Text>
      <Text fontSize="T200" fontWeight="regular">
        If you think you need urgent help, please
        <Link href="https://t.corp.amazon.com/create/templates/dfc15985-f663-4f58-8cf7-a625f09d0565">
          {" "}
          contact us
        </Link>{" "}
        .
      </Text>
    </Fragment>
  );
};
