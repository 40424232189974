import { Order } from "@amzn/ito-client";
import { Card } from "@amzn/stencil-react-components/card";
import { Col, Flex, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import { WithModal } from "@amzn/stencil-react-components/modal";
import { Pagination } from "@amzn/stencil-react-components/pagination";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { RowAttributesFunction, Table, TableSpacing } from "@amzn/stencil-react-components/table";
import { Text } from "@amzn/stencil-react-components/text";
import { ToggleButton, ToggleButtonGroup } from "@amzn/stencil-react-components/toggle-button";
import { ColumnSetting } from "common/types/config-model";
import { COMPLETED, PENDING_APPROVAL } from "common/types/orders-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC } from "react";

import { DeclineOrderModal } from "./components/DeclineOrderModal/DeclineOrderModal";
import ordersApprovalTableConfig from "./orders-approval.table.config.json";
import { buildColumnDefinitions } from "./orders-approval-table-utils";
import { useOrdersApproval } from "./useOrdersApproval";

export const MyOrdersApprovalTable: FC = () => {
  const {
    approveOrder,
    orders,
    reloadOrders,
    isLoading,
    selectedOption,
    handleSelectedOption,
    handlePageChange,
    numberOfPages,
    currentPage,
    errorMessage,
    successMessage,
    selectedOrder,
    setSelectedOrder,
    setErrorMessage,
    setSuccessMessage,
  } = useOrdersApproval();

  /* istanbul ignore next */
  const expandableContent = ({ data, index }: any) => {
    const order: Order = data;
    const content = buildContent(order, index);

    return content;
  };

  const renderModal = ({ close }: { close: () => void }) => {
    const proxyClose = (shouldReload: boolean) => {
      close();
      if (shouldReload) {
        reloadOrders();
      }
    };
    return (
      <DeclineOrderModal
        close={proxyClose}
        order={selectedOrder}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
      ></DeclineOrderModal>
    );
  };

  /* eslint-disable @typescript-eslint/no-unused-vars */
  /* istanbul ignore next */
  const getRowAttributes: RowAttributesFunction = ({ data }: any) => ({
    expandableContent: (data as Order).status === PENDING_APPROVAL ? expandableContent : undefined,
    disabled: false,
  });

  if (isLoading) {
    return (
      <Flex flexDirection="column">
        <View alignSelf="center">
          <Spacer height={32} width={32} />
          <Spinner size={SpinnerSize.Medium} showText loadingText={getString("myOrdersApproval.loading")} />
        </View>
      </Flex>
    );
  }

  return (
    <>
      {errorMessage && (
        <Flex flexDirection="column">
          <MessageBanner
            dismissButtonAltText={getString("myOrdersApproval.dismissMessage")}
            isDismissible
            onDismissed={() => {
              setErrorMessage("");
            }}
            type={MessageBannerType.Error}
          >
            {errorMessage}
          </MessageBanner>
        </Flex>
      )}
      {successMessage && (
        <Flex flexDirection="column">
          <MessageBanner
            isDismissible
            dismissButtonAltText={getString("myOrdersApproval.dismissMessage")}
            onDismissed={() => {
              setSuccessMessage("");
            }}
            type={MessageBannerType.Success}
          >
            {successMessage}
          </MessageBanner>
        </Flex>
      )}
      <WithModal renderModal={renderModal} shouldCloseOnClickOutside={false}>
        {({ open }: { open: () => void }) => {
          const columns: ColumnSetting[] = ordersApprovalTableConfig.columns;
          const currentColumnDefinitions = buildColumnDefinitions(columns, selectedOption!, {
            approveOrder,
            setSelectedOrder,
            open,
          });

          return (
            <Col gridGap={32} padding={"S400"}>
              <Row justifyContent="flex-end">
                <ToggleButtonGroup value={selectedOption} onChange={handleSelectedOption}>
                  <ToggleButton value={PENDING_APPROVAL}>{getString("myOrdersApproval.pending")}</ToggleButton>
                  <ToggleButton value={COMPLETED}>{getString("myOrdersApproval.completed")}</ToggleButton>
                </ToggleButtonGroup>
              </Row>
              <Table
                actionHeader=""
                columns={currentColumnDefinitions}
                data={orders}
                spacing={TableSpacing.Default}
                shouldAutoCollapseOnExpand={true}
                getRowAttributes={getRowAttributes}
              />
              <Row justifyContent="center">
                <Pagination
                  numberOfPages={numberOfPages}
                  onPageSelect={handlePageChange}
                  selectedPage={currentPage}
                ></Pagination>
              </Row>
            </Col>
          );
        }}
      </WithModal>
    </>
  );
};

/**
 * Builds the content for the expanded row based on the status of the order
 * @param data
 * @param index
 * @returns
 */
/* istanbul ignore next */
const buildContent = (data: Order, index: number): React.JSX.Element[] => {
  const innerContent: React.JSX.Element[] = [];

  if (data.items && data.items.length > 0) {
    const details: any = data.items[0].details;
    const requestReason = details?.comment;

    if (requestReason) {
      innerContent.push(
        <Flex flexDirection="column">
          <View alignSelf="center" width="50%">
            <Card>
              <Col gridGap={32}>
                <Text fontSize="T200" fontWeight={600}>
                  {getString("myOrdersApproval.reasonForRequest")}
                </Text>
                <Text fontSize="T200" color="neutral70">
                  {requestReason}
                </Text>
              </Col>
            </Card>
          </View>
        </Flex>
      );
    }
  }

  return innerContent;
};
