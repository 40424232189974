import { PERMISSIONS } from "config";
import { StatusCodes } from "http-status-codes";
import * as cache from "memory-cache";

import { Catalinav2BaseService } from "./catalinav2-base-service";

const PERMISSIONS_CACHE_KEY = "PERMISSIONS";
const PERMISSIONS_CACHE_EXPIRATION_IN_MILLISECONDS = 15 * 60 * 1000; // 15 minutes

/**
 * Implements user Validation, Authorization and Authentication.
 */
export class UserPermissions extends Catalinav2BaseService {
  /**
   * Singleton instance with the current user permissions.
   */
  public static current = new UserPermissions();

  private _username: string | null = null;

  public get isAuth(): boolean {
    return !!this._username;
  }

  /**
   * Retrieves the username of the currently authenticated user.
   */
  public get username(): string | null {
    return this._username;
  }

  /**
   * Retrieves the list of permissions authorized to the current user.
   */
  public get permissions(): PERMISSIONS[] {
    const permissions = cache.get(PERMISSIONS_CACHE_KEY) as PERMISSIONS[];
    if (!permissions) {
      throw new Error("The user has not been authorized.");
    }

    return permissions;
  }

  /**
   * Identifies whether or not the current used has been through the authorization process, irrespective of the result.
   * @returns True if the user has been through the authorization process.
   */
  public get hasAuthorized(): boolean {
    return cache.get(PERMISSIONS_CACHE_KEY) != null;
  }

  /**
   * Identifies whether or not the current user has a given permission.
   * @param permission The permission to check.
   * @returns True if the user has the specified permission.
   */
  public hasPermission(permission: PERMISSIONS): boolean {
    return this.permissions.includes(permission);
  }

  /**
   * Executes authorization (and indirectly authentication) for the current user.
   */
  public async authorize() {
    if (!cache.get(PERMISSIONS_CACHE_KEY)) {
      cache.put(
        PERMISSIONS_CACHE_KEY,
        await this.callPermissionsService(),
        PERMISSIONS_CACHE_EXPIRATION_IN_MILLISECONDS
      );
    }
  }

  private async callPermissionsService(): Promise<PERMISSIONS[]> {
    const response = await this.fetch("/user-permissions");
    if (response.status !== StatusCodes.OK) {
      throw new Error("An error occurred when authorizing the user.");
    }
    const jsonResponse = await response.json();
    if (jsonResponse.failureReason) {
      throw new Error(jsonResponse.failureReason);
    }
    this._username = jsonResponse.username;
    return jsonResponse.allowedFeatures as PERMISSIONS[];
  }
}
