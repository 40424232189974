import "./style.scss";

import { Col, Row, View } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { TrafficFrom } from "common/helpers/traffic-from";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FC, useEffect, useState } from "react";

import { CatalogicalService } from "../../../lib/services/catalogical-service";
import { CatalogicalItem } from "../../types/catalogical-type";
import { CatalogItemCard } from "../CatalogItemCard";
import { useMessageBannersBox } from "../MessageBannersBox/hook";

export interface CatalogicalRecommendedItemsProps {
  pageSize: number;
  categoryToFilterBy: string;
  children?: any;
}

const CatalogicalRecommendedItems: FC<CatalogicalRecommendedItemsProps> = ({
  categoryToFilterBy,
  pageSize,
  children,
}) => {
  const { addErrorBanner } = useMessageBannersBox();
  const [itemsData, setItemsData] = useState<CatalogicalItem[] | null>(null);
  const client = new CatalogicalService();

  useEffect(() => {
    fetchData();
  }, [categoryToFilterBy, pageSize]);

  /* istanbul ignore next */
  const fetchData = async () => {
    try {
      const requestParams = {
        limit: pageSize,
        offset: 0,
        sortBy: "name",
        sortDirection: "asc",
        filter: JSON.stringify({ categories: categoryToFilterBy }),
      };
      const response = await client.getCatalogicalItems(requestParams);
      if (!response.data) throw new Error("No item data found");
      setItemsData(response.data);
    } catch (error) {
      setItemsData([]);
      addErrorBanner(getString("errors.general"), error);
    }
  };

  /* istanbul ignore next */
  if (itemsData) {
    return (
      <View>
        <Row
          key={categoryToFilterBy}
          className={`recommended-items recommended-items-${categoryToFilterBy} recommended-items-by-${pageSize}`}
        >
          {itemsData.map((item, i) => (
            <Col
              key={i}
              className={`recommended-item recommended-item-${categoryToFilterBy}`}
              data-cy={`recommended-item-${categoryToFilterBy}`}
              padding={["S100", "S500", 0, 0]}
            >
              <CatalogItemCard
                from={TrafficFrom.Recommended}
                name={item.name}
                shortDescription={item.short_description}
                logoUrl={item.logo_url}
                taxonomyId={item.taxonomyId}
              />
            </Col>
          ))}
        </Row>
        {children}
      </View>
    );
  } else {
    return (
      <View alignItems="center">
        <Spinner size={SpinnerSize.Small} />
      </View>
    );
  }
};

export default CatalogicalRecommendedItems;
