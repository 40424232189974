import "./style.scss";

import { Col, Hr, Row, View } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { TrafficFrom } from "common/helpers/traffic-from";
import { ItemTaxonomyId } from "lib/taxonomy/item-taxonomy-id";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";

export interface SearchResultProps {
  taxonomyId: string;
  logo_url: string;
  name: string;
  short_description: string;
  status: string;
}

export const SearchResult: FC<SearchResultProps> = (props: SearchResultProps) => {
  const history = useHistory();
  let detailsUrl = "#";

  // Since some catalog items will not have a strain value, parsing the taxonomy
  // could throw an error, handling this with a try/catch in the meantime
  try {
    detailsUrl = `items/${ItemTaxonomyId.Parse(props.taxonomyId!).getUrlSuffix()}?from=${TrafficFrom.Search}`;
  } catch (error) {
    // TODO: report this error to cloudwatch. (To be resolved by ITMP-344)
  }

  /* istanbul ignore next */
  const onSearchResultClick = (event: any) => {
    if (detailsUrl !== "#") {
      if (event.ctrlKey) return true; // allows a new tab if ctrl + click

      history.push(detailsUrl);
      event.preventDefault();
    }
  };

  return (
    <View className={`catalog-result-view catalog-item catalog-item-${props.status}`} padding={[0, "S300", 0, "S300"]}>
      <Link
        href={detailsUrl}
        className="catalog-result-link"
        onClick={onSearchResultClick}
        textDecoration="none"
        color="neutral90"
        data-cy="catalog-result-link"
      >
        <Row width="100%" alignItems="top" padding="S300">
          <Col padding={[0, "S300", 0, 0]}>
            <Row>
              <img
                data-testid="catalog-item-url"
                height={70}
                width={70}
                src={props.logo_url}
                alt={`${props.name} logo`}
              ></img>
            </Row>
          </Col>
          <Col>
            <Row>
              <Text fontSize="T200" fontWeight="bold">
                {props.name}
              </Text>
            </Row>
            <Text fontSize="T200" fontWeight="regular">
              {props.short_description}
            </Text>
          </Col>
        </Row>
      </Link>
      <Hr />
    </View>
  );
};
