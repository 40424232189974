import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CatalogicalGetAllItemsResponse,
  CatalogicalItem,
  CatalogicalRequestContent,
} from "common/types/catalogical-type";
import { produce } from "immer";
import { CatalogicalService } from "lib/services/catalogical-service";
import { AppState } from "modules/app-reducer";

const actionDomain = "catalogItems";

const catalogicalService = new CatalogicalService();

export const fetchCatalogItems = createAsyncThunk(
  `${actionDomain}/fetch`,
  async (searchParams: CatalogicalRequestContent) => {
    return await catalogicalService.getCatalogicalItems(searchParams);
  }
);

export interface CatalogicalItemState {
  items: CatalogicalItem[] | undefined;
  nextToken: number | undefined;
  searchParams?: CatalogicalRequestContent;
  totalResults: number | undefined;
  facets: { categories: string[]; os: string[] } | undefined;
  currentOffset: number;
}

const defaultState: CatalogicalItemState = {
  items: [],
  searchParams: undefined,
  totalResults: 0,
  facets: undefined,
  nextToken: 0,
  currentOffset: 0,
};

const catalogicalItemsSlice = createSlice({
  name: "CatalogicalItemsSlice",
  initialState: defaultState,
  reducers: {
    updateSearchParametersAction(state, action: PayloadAction<CatalogicalRequestContent>) {
      state.searchParams = action.payload;
    },
    updateFacetsAction(state, action: PayloadAction<{ categories: string[]; os: string[] } | undefined>) {
      state.facets = action.payload;
    },
    updateNextTokenAction(state, action: PayloadAction<number | undefined>) {
      state.nextToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogItems.fulfilled, (state, action: PayloadAction<CatalogicalGetAllItemsResponse>) => {
        const existingCategories = new Set(state.facets?.categories || []);
        const existingOs = new Set(state.facets?.os || []);
        const categories = new Set<string>();
        const os = new Set<string>();

        action.payload.data.forEach((item) => {
          item.categories?.forEach((category) => categories.add(category));
          item.os?.forEach((osItem) => os.add(osItem));
        });

        const mergedCategories = new Set([...existingCategories, ...categories]);
        const mergedOs = new Set([...existingOs, ...os]);

        state.facets = {
          categories: Array.from(mergedCategories),
          os: Array.from(mergedOs),
        };

        const nextState = addItems(state, action);
        state.items = nextState.items;
        state.totalResults = action.payload.pagination.total;
      })
      .addCase(fetchCatalogItems.rejected, (state) => {
        state.items = [];
        state.totalResults = 0;
        state.facets = undefined;
      });
  },
});

const addItems = (state: CatalogicalItemState, action: PayloadAction<CatalogicalGetAllItemsResponse>) => {
  return produce(state, (draft: CatalogicalItemState) => {
    if (action.payload.pagination.offset && action.payload.pagination.offset != null) {
      draft.items = [...draft.items!, ...action.payload.data!];
    } else {
      draft.items = action.payload.data;
    }

    return draft;
  });
};

export const { updateSearchParametersAction, updateFacetsAction, updateNextTokenAction } =
  catalogicalItemsSlice.actions;

export const selectNextToken = (state: AppState): number | undefined => state.catalogicalItems.nextToken;
export const selectSearchParameters = (state: AppState): CatalogicalRequestContent | undefined =>
  state.catalogicalItems.searchParams;
export const selectResultItems = (state: AppState): CatalogicalItem[] | undefined => state.catalogicalItems.items;
export const selectTotalResultCount = (state: AppState): number | undefined => state.catalogicalItems.totalResults;
export const selectFacets = (state: AppState): { categories: string[]; os: string[] } | undefined =>
  state.catalogicalItems.facets;
export const selectCurrentOffset = (state: AppState) => state.catalogicalItems.currentOffset;

export const catalogicalItemsReducer = catalogicalItemsSlice.reducer;
