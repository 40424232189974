import { OrderingConfig, SiteConfiguration } from "configuration/site-configuration";

export const BASE_DOMAIN = "marketplace.it.a2z.com";
export const PROD_DOMAIN = BASE_DOMAIN;
export const GAMMA_DOMAIN = "gamma." + BASE_DOMAIN;
export const BETA_DOMAIN = "beta." + BASE_DOMAIN;
export const DEV_DOMAIN = "dev." + BASE_DOMAIN;
export const SANDBOX_DOMAIN = "sandbox." + BASE_DOMAIN;

export class PERMISSIONS {
  // eslint-disable-next-line no-unused-vars
  public static BRAVE_HEART = "braveheart";
  // eslint-disable-next-line no-unused-vars
  public static USE_V2 = "v2";
  // eslint-disable-next-line no-unused-vars
  public static SOFTWARE_PROCUREMENT = "software_procurement"; // For now, this permission is only for unit tests, in the future this should be replaced by actual permissions
}

export interface MaintenanceSettings {
  enabled?: boolean;
}

/**
 * Gets a configuration value depending on the environment stage.
 */
export function getConfigByEnv(): SiteConfiguration {
  switch (location.hostname) {
    case PROD_DOMAIN:
      return require(`configuration/config.prod.json`);
    case GAMMA_DOMAIN:
      return require(`configuration/config.gamma.json`);
    case BETA_DOMAIN:
      return require(`configuration/config.beta.json`);
    case DEV_DOMAIN:
      return require(`configuration/config.dev.json`);
    case SANDBOX_DOMAIN:
      return require(`configuration/config.sandbox.json`);
    default:
      return require(`configuration/config.local.json`);
  }
}

const config = getConfigByEnv();
export const API_BASE_URL = config.apiBaseUrl;
export const ITMP_V1_URL = config.v1BaseUrl;
export const ITMP_V2_ADMIN_URL = config.v2AdminUrl;
export const CATALOG_SERVICE_ENDPOINT = config.catalogServiceEndpoint;
export const CATALOGICAL_ENDPOINT = config.apiBaseUrl + "/catalogical";
export const ORDERING_CONFIG: OrderingConfig = config.ordering;
export const DEFAULT_SERVICE_REGION = config.region;

export const IS_MAINTENANCE_MODE_ENABLED = process.env.ENABLE_MAINTENANCE_MODE === "true" || false;
/**
 * Order statuses to export
 */
export const EXPORTABLE_STATUSES = ["ready-for-export"];

/* This variable will be fixed in https://sim.amazon.com/issues/ITMP-1208, 
 it is used to make the old catalog service components' tests pass */
export const IS_V1 = process.env.V1_CATALOG_SERVICE;
