import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { UserReference } from "common/types/orders-types";
import React, { FC } from "react";

export interface InlineUserReferenceProps {
  userReference: UserReference;
  fontSize?: string;
}

// Returns inline reference of an User
export const InlineUserReference: FC<InlineUserReferenceProps> = ({
  userReference,
  fontSize = "T200",
}: InlineUserReferenceProps) => {
  const phonetoolLinkComponent = (
    <Link
      href={`https://phonetool.amazon.com/users/${userReference.username}`}
      target="_blank"
    >{`${userReference.username}@`}</Link>
  );

  return (
    <Text display="inline" fontSize={fontSize}>
      {`${userReference!.name} ${userReference!.surname}`} ({phonetoolLinkComponent})
    </Text>
  );
};
