import { ITOServiceException, Order } from "@amzn/ito-client";
import { Col, Container, Row } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Text } from "@amzn/stencil-react-components/text";
import { PreParse, TaxonomyIdOrder } from "@amzn/taxonomy-lib";
import { TopBar } from "common/components";
import { useMessageBannersBox } from "common/components/MessageBannersBox/hook";
import { OrderDetail } from "common/components/OrderDetail";
import { getString } from "common/uistringlabels/uiStringUtils";
import { ITOOrdersService } from "lib/services";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface ApprovalPageParams {
  orderId: string;
  newStatus: string;
}

// TODO add proper unit test
/* istanbul ignore next */
const ApprovalPage: FC = () => {
  const { addErrorBanner } = useMessageBannersBox();

  // Get url params
  const params = useParams<ApprovalPageParams>();
  const orderIdTx = new TaxonomyIdOrder(params.orderId, PreParse.AS_BASE64);

  // Prepare states
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [isLoading, setLoading] = useState(true);

  // Set initial state
  useEffect(() => {
    setLoading(true);
    updateOrderStatus();
  }, []);

  // About order
  const ito = new ITOOrdersService();

  const updateOrderStatus = async () => {
    const token = await ito.GetSecurityToken();
    try {
      const result: Order = await ito.UpdateStatus(orderIdTx.toString(), params.newStatus, token);
      if (result) {
        setOrder(result);
        setLoading(false);
      } else {
        await fetchOrder();
      }
    } catch (e) {
      const error = e as ITOServiceException;
      console.debug(error);

      if (error && error.$metadata && error.$metadata.httpStatusCode && error.$metadata.httpStatusCode === 404) {
        addErrorBanner(getString("errors.notFound"), error);
      }
      if (error && error.$metadata && error.$metadata.httpStatusCode && error.$metadata.httpStatusCode === 417) {
        addErrorBanner(getString("errors.alreadyProcessed"), error);
      } else {
        addErrorBanner(getString("errors.general"), e);
      }
      await fetchOrder();
    }
  };

  const fetchOrder = async () => {
    try {
      const result = await ito.GetOrder(orderIdTx.toString());
      setOrder(result);
      setLoading(false);
    } catch (e) {
      const error = e as ITOServiceException;
      if (error && error.$metadata && error.$metadata.httpStatusCode && error.$metadata.httpStatusCode === 404) {
        addErrorBanner(getString("errors.notFound"), error);
      }
      if (error && error.$metadata && error.$metadata.httpStatusCode && error.$metadata.httpStatusCode === 417) {
        addErrorBanner(getString("errors.alreadyProcessed"), error);
      } else {
        addErrorBanner(getString("errors.general"), e);
      }
      setLoading(false);
    }
  };

  return (
    <PageContainer paddingHorizontal={0} paddingTop={0} isFullWidth={true}>
      <TopBar>
        <Text fontSize="T300" color="neutral0">
          {getString("approvalPage.title")}
        </Text>
      </TopBar>
      <Container isFullWidth={false}>
        <Col gridGap="S200" width={"100%"}>
          <Row padding="S300" gridGap="S400" width={"100%"}>
            <Col gridGap="S200" width={"100%"}>
              <OrderDetail isLoading={isLoading} orderIdTx={orderIdTx} order={order} />
            </Col>
          </Row>
        </Col>
      </Container>
    </PageContainer>
  );
};

export default ApprovalPage;
