import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "modules/app-reducer";

import { ApprovalRequestsLinkProps } from "./hook";

export interface ApprovalRequestsLinkState {
  linkState: ApprovalRequestsLinkProps;
}

const defaultState: ApprovalRequestsLinkState = {
  linkState: {
    isManager: false,
    pendingOrdersCount: undefined,
  },
};

/**
 * Contains the redux reducers logic to update the state
 */
const approvalRequestsLinkSlice = createSlice({
  name: "ApprovalRequestsLink",
  initialState: defaultState,
  reducers: {
    pendingOrdersChange(state, action: PayloadAction<ApprovalRequestsLinkProps>) {
      state.linkState = action.payload;
    },
  },
});

/**
 * Selector for is manager
 * @param state App state
 * @returns isManager flag
 */
export const selectIsManager = (state: AppState): boolean => state.approvalRequestsLinkState.linkState.isManager;

/**
 * Selector for pending orders count
 * @param state App state
 * @returns Pending orders count
 */
export const selectPendingOrdersCount = (state: AppState): string | undefined =>
  state.approvalRequestsLinkState.linkState.pendingOrdersCount;

export const approvalRequestsLinkReducer = approvalRequestsLinkSlice.reducer;
export const { pendingOrdersChange } = approvalRequestsLinkSlice.actions;
