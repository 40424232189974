import { Order } from "@amzn/ito-client";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Row } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Status, StatusIndicator } from "@amzn/stencil-react-components/status-indicator";
import { usePendingOrdersLink } from "common/components/ApprovalRequestsLink/hook";
import { getString } from "common/uistringlabels/uiStringUtils";
import { UserPermissions } from "lib/services";
import React, { Dispatch, FunctionComponent, SetStateAction, useState } from "react";

export interface ApproveDenyButtonsProps {
  setSelectedOrder: Dispatch<SetStateAction<Order | undefined>>;
  selectedOrder?: Order;
  open: () => void;
  approveOrder: (order: Order) => void;
}

export const ApproveDenyButtons: FunctionComponent<ApproveDenyButtonsProps> = ({
  approveOrder,
  setSelectedOrder,
  selectedOrder,
  open,
}: ApproveDenyButtonsProps) => {
  const iAmApprover = UserPermissions.current.username === selectedOrder?.approver?.username;
  const [isApproving, setIsApproving] = useState(false);
  const { refreshPendingOrderCount } = usePendingOrdersLink();

  /* istanbul ignore next */
  const onApproveClick = async () => {
    setIsApproving(true);
    await approveOrder(selectedOrder!);
    refreshPendingOrderCount();
    setIsApproving(false);
  };

  /* istanbul ignore next */
  const onDeclineClick = () => {
    setSelectedOrder(selectedOrder);
    open();
  };

  const needsHigherLevelManager = !iAmApprover || isApproving;

  return (
    <div>
      {!iAmApprover && (
        <Row>
          <StatusIndicator
            messageText={`Must be approved by ${selectedOrder?.approver?.username}@`}
            status={Status.Unavailable}
          />
        </Row>
      )}
      <Row>
        <Button dataTestId="approve-button" disabled={needsHigherLevelManager} onClick={onApproveClick}>
          {getString("myOrdersApproval.approve")}{" "}
          {iAmApprover && isApproving && <Spinner size={SpinnerSize.Small}></Spinner>}
        </Button>
        <Button
          disabled={needsHigherLevelManager}
          dataTestId="decline-button"
          variant={ButtonVariant.Tertiary}
          onClick={onDeclineClick}
        >
          {getString("myOrdersApproval.decline")}
        </Button>
      </Row>
    </div>
  );
};
