/* istanbul ignore file */
import { combineReducers } from "@reduxjs/toolkit";
import { approvalRequestsLinkReducer, ApprovalRequestsLinkState } from "common/components/ApprovalRequestsLink/reducer";
import { MessageBannerBoxState, messageBannersBoxReducer } from "common/components/MessageBannersBox/reducer";

import { catalogicalItemsReducer, CatalogicalItemState } from "./components/CatalogicalItemsList";
export interface AppState {
  /**
   * State for the catalogical items data
   */
  catalogicalItems: CatalogicalItemState;

  /**
   * State for the message banners box data
   */
  messageBannersBox: MessageBannerBoxState;

  /**
   * State for pending orders count
   */
  approvalRequestsLinkState: ApprovalRequestsLinkState;
}

const appReducer = combineReducers<AppState>({
  catalogicalItems: catalogicalItemsReducer,
  messageBannersBox: messageBannersBoxReducer,
  approvalRequestsLinkState: approvalRequestsLinkReducer,
});

export default appReducer;
