import "./style.scss";

import { Col, Container, Row, View } from "@amzn/stencil-react-components/layout";
import { Hr } from "@amzn/stencil-react-components/layout";
import { WithModal } from "@amzn/stencil-react-components/modal";
import { Text } from "@amzn/stencil-react-components/text";
import parse from "html-react-parser";
import React, { FunctionComponent } from "react";
import { CatalogicalDetailsPageProps } from "view/Page/CatalogicalDetailsPage";

import { getString } from "../../uistringlabels/uiStringUtils";
import { CustomerFeedback, CustomerFeedbackAlignment, CustomerFeedbackIconPosition } from "../CustomerFeedback";
import { OrderNow } from "../OrderNow";
import { SubmitOrderModal } from "../SubmitOrderModal";

export const CatalogicalProductDetailsPage: FunctionComponent<CatalogicalDetailsPageProps> = ({
  id,
  item,
  preCheckoutInformation,
  fetchPreCheckoutData,
}: CatalogicalDetailsPageProps) => {
  if (!item) return <div>No item data</div>;

  if (!item?.status) return <div>No default version</div>;

  const renderModal = ({ close }: { close: () => void }) => {
    return (
      <SubmitOrderModal
        close={close}
        preCheckoutData={preCheckoutInformation}
        fetchPreCheckoutData={fetchPreCheckoutData!}
        taxonomyId={item.taxonomyId}
        name={item.name}
        status={item.status!}
        etaDays={item.eta_days!}
        hasStock={item.has_stock!}
      />
    );
  };

  return (
    <WithModal renderModal={renderModal}>
      {({ open }: { open: () => void }) => (
        <>
          <Container className="product-details" paddingBottom="S500" paddingTop="S500" paddingHorizontal="S300">
            <View>
              <Row key={id} gridGap="S700" alignItems="center" className="product-description-box">
                <Col className="column-left">
                  <Row gridGap="S100">
                    <img
                      data-details-logo="details-logo"
                      className="product-img"
                      src={item.logo_url}
                      alt={`${item.name} logo`}
                    ></img>
                  </Row>
                </Col>
                <Col className="column-center">
                  <Text fontSize="T200" className="product-title" data-cy="product-title">
                    {item.name}
                  </Text>

                  {item.short_description && (
                    <div className="product-short-details" data-cy="product-short-details">
                      <Text
                        data-details-description="details-description"
                        fontSize="T100"
                        className="product-description"
                      >
                        {item.short_description}
                      </Text>
                    </div>
                  )}

                  <hr />

                  <Row columnGap={60} gridGap={0}>
                    <Text className="product-property">
                      <label data-cy="os-title">{getString("details.os")}</label>
                      <br />
                      <strong>{item.os?.join(", ")}</strong>
                    </Text>

                    <Text className="product-property">
                      <label data-cy="publisher-title">{getString("details.publisher")}</label>
                      <br />
                      <strong>{item.manufacturer}</strong>
                    </Text>

                    <Text className="product-property">
                      <label data-cy="category-title">{getString("details.category")}</label>
                      <br />
                      <strong>{item.categories?.join(", ")}</strong>
                    </Text>
                  </Row>
                </Col>
                <Col className="column-right">
                  <OrderNow
                    orderNowHandler={open}
                    etaStatusIndicatorProps={{
                      hasStock: item.has_stock ?? false,
                      etaDays: item.eta_days ?? 0,
                    }}
                    orderRedirectUrl={item.references?.order_redirect_url || ""}
                    moreDetailsUrl={item.references?.more_details_url || ""}
                    status={item.status || "unavailable"}
                    amount={item.price?.amount || 0}
                    currency={item.price?.currency || "USD"}
                  />
                </Col>
              </Row>
            </View>
          </Container>

          <Container className="about-product-view" paddingBottom="S500" paddingTop="S500" paddingHorizontal="S300">
            <View>
              <Row
                key={id}
                gridGap="S700"
                alignItems="center"
                className="product-description-box"
                data-cy="product-description-box"
              >
                <Col className="column-left"></Col>
                <Col className="column-center">
                  {item.description && (
                    <View>
                      <div>{parse(item.description)}</div>
                      <Hr color="neutral30" />
                      <CustomerFeedback
                        moduleName="productDetails"
                        alignment={CustomerFeedbackAlignment.Left}
                        position={CustomerFeedbackIconPosition.Left}
                      ></CustomerFeedback>
                    </View>
                  )}
                </Col>
                <Col className="column-right"></Col>
              </Row>
            </View>
          </Container>
        </>
      )}
    </WithModal>
  );
};
