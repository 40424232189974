import "./style.scss";

import { Card } from "@amzn/stencil-react-components/card";
import {
  IconCloseCircleFill,
  IconThumbDown,
  IconThumbDownFill,
  IconThumbUp,
  IconThumbUpFill,
} from "@amzn/stencil-react-components/icons";
import { Col, Row, Spacer } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { getString } from "common/uistringlabels/uiStringUtils";
import { UtilHelper } from "lib/helpers";
import React, { FunctionComponent, useState } from "react";

export interface CustomerFeedbackProps {
  /**
   * Module name to load the questions and the title to load
   */
  moduleName: string;

  /**
   * The text alignment for the text
   */
  alignment: CustomerFeedbackAlignment;

  /**
   * The thumbs up icon position
   */
  position: CustomerFeedbackIconPosition;
}

export enum CustomerFeedbackAlignment {
  Left = 1,
  Centered,
  Right,
}

export enum CustomerFeedbackIconPosition {
  Left = "left",
  Bottom = "none",
}

export const CustomerFeedback: FunctionComponent<CustomerFeedbackProps> = ({ moduleName, alignment, position }) => {
  const [upvoted, setUpvoted] = useState(false);
  const [downvoted, setDownvoted] = useState(false);
  const [cfmActive, setCfmActive] = useState(false);

  // istanbul ignore next
  const resetCfmWidget = (event: any) => {
    event.preventDefault();
    const widget = document.getElementById(`cfm-widget-${moduleName}`);
    if (cfmActive) {
      // @ts-ignore
      widget?.destroy();
      setUpvoted(false);
      setDownvoted(false);
      setCfmActive(false);
    }

    return false;
  };

  // istanbul ignore next
  const onUpvoteClick = (event: any) => {
    const widget = document.getElementById(`cfm-widget-${moduleName}`);
    event.preventDefault();
    const wasUpvoted = !upvoted;
    resetCfmWidget(event);
    setUpvoted(wasUpvoted);
    if (wasUpvoted) {
      const configSettings = UtilHelper.getCustomerFeedbackConfiguration(`${moduleName}`);
      // initialize CFM widget and start
      // @ts-ignore
      widget?.initialize(configSettings);
      // start CFM script
      // @ts-ignore
      widget?.start();
      // Preselect the highest rating
      // WARNING: This is not oficially supported, calling private method to set the rating to 5
      // @ts-ignore
      widget?._ratingClickHandler(5);

      setCfmActive(true);
    }

    return false;
  };

  // istanbul ignore next
  const onDownvoteClick = (event: any) => {
    const widget = document.getElementById(`cfm-widget-${moduleName}`);
    event.preventDefault();
    const wasDownvoted = !downvoted;
    resetCfmWidget(event);
    setDownvoted(wasDownvoted);
    if (wasDownvoted) {
      const configSettings = UtilHelper.getCustomerFeedbackConfiguration(`${moduleName}`);
      // initialize CFM widget and start
      // @ts-ignore
      widget?.initialize(configSettings);
      // start CFM script
      // @ts-ignore
      widget?.start();
      // Preselect the lowest rating
      // WARNING: This is not oficially supported, calling private method to set the rating to 0
      // @ts-ignore
      widget?._ratingClickHandler(0);

      setCfmActive(true);
    }

    return false;
  };

  // istanbul ignore next
  const getCardDisplay = (): string => {
    if (upvoted || downvoted) {
      return "flex";
    }
    return "none";
  };

  // instanbul ignore next
  const getIconPositioning = (): React.CSSProperties => {
    if (position == CustomerFeedbackIconPosition.Bottom) {
      return {
        alignItems: "center",
        flexDirection: "column",
      };
    }
    return {};
  };

  // instanbul ignore next
  const getPopUpCardHeight = (): string => {
    return position == CustomerFeedbackIconPosition.Bottom ? "-150px" : "30px";
  };

  // instanbul ignore next
  const getRowTopMargin = (): string => {
    return position == CustomerFeedbackIconPosition.Bottom ? "0" : "S400";
  };

  return (
    <>
      <Row width="100%" margin={[getRowTopMargin(), 0, 0, 0]}>
        {(alignment === CustomerFeedbackAlignment.Right || alignment == CustomerFeedbackAlignment.Centered) && (
          <Spacer flex="1"></Spacer>
        )}
        <div className="cfm-main" style={getIconPositioning()}>
          <div>
            {/* Text */}
            <Text
              className="cfm-text"
              fontSize="T100"
              fontWeight="bold"
              margin={[0, "S200", 0, 0]}
              data-cy={`cfm-title-${moduleName}`}
            >
              {getString(`customerFeedback.${moduleName}`)}
            </Text>
          </div>
          <div>
            {/* Icons */}
            <Link
              href="#"
              margin={[0, "S200", 0, 0]}
              onClick={onUpvoteClick}
              className="thumbsup-icon"
              data-cy={`${moduleName}-thumbsup`}
            >
              {!upvoted && <IconThumbUp title={getString("customerFeedback.thumbsUpTitle")} color="neutral70" />}
              {upvoted && <IconThumbUpFill title={getString("customerFeedback.thumbsUpTitle")} color="primary80" />}
            </Link>
            <Link href="#" onClick={onDownvoteClick} className="thumbsup-icon" data-cy={`${moduleName}-thumbsdown`}>
              {!downvoted && <IconThumbDown title={getString("customerFeedback.thumbsUpTitle")} color="neutral70" />}
              {downvoted && <IconThumbDownFill title={getString("customerFeedback.thumbsUpTitle")} color="primary80" />}
            </Link>
          </div>
        </div>
        {/* Pop Up Card */}
        <div style={{ display: getCardDisplay(), position: "relative" }}>
          <Card isElevated={true} style={{ position: "absolute", left: "-411px", top: getPopUpCardHeight() }}>
            <Col>
              <Row>
                <Spacer flex="1"></Spacer>
                <Link href="#" onClick={resetCfmWidget}>
                  <IconCloseCircleFill title={getString("customerFeedback.closeCFM")} color="primary80" />
                </Link>
              </Row>
              <Row>
                <git-feedback-inline-widget
                  id={`cfm-widget-${moduleName}`}
                  data-cy={`cfm-widget-${moduleName}`}
                ></git-feedback-inline-widget>
              </Row>
            </Col>
          </Card>
        </div>
        {alignment === CustomerFeedbackAlignment.Centered && <Spacer flex="1"></Spacer>}
      </Row>
    </>
  );
};
