import { useAppDispatch, useAppSelector } from "common/hooks";
import { PENDING_APPROVAL } from "common/types/orders-types";
import { ITOOrdersService } from "lib/services";

import { pendingOrdersChange, selectIsManager, selectPendingOrdersCount } from "./reducer";

export interface ApprovalRequestsLinkProps {
  /**
   * Number of pending orders
   */
  pendingOrdersCount?: string;

  /**
   * Is manager flag
   */
  isManager: boolean;
}

export interface usePendingOrdersLinkResult {
  isManager: boolean;
  pendingOrdersCount?: number;
  refreshPendingOrderCount: () => void;
}

export const usePendingOrdersLink = () => {
  const dispatch = useAppDispatch();
  const isManager = useAppSelector(selectIsManager);
  const pendingOrdersCount = useAppSelector(selectPendingOrdersCount);

  const refreshPendingOrderCount = async () => {
    const ito = new ITOOrdersService();
    const { isManager, totalResults } = await ito.HeadOrdersDirect({ status: [PENDING_APPROVAL] });
    dispatch(pendingOrdersChange({ isManager, pendingOrdersCount: totalResults }));
  };

  return {
    isManager,
    pendingOrdersCount,
    refreshPendingOrderCount,
  };
};
