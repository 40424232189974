import get from "lodash/get";

import uiStrings from "./uistrings.json";

export const getString = (id: string, obj?: { [key: string]: any }): string => {
  let outputString = get(uiStrings, id, "");
  if (obj != null) {
    for (const key in obj) {
      const formatKey = "{{" + key + "}}";
      outputString = outputString.replace(formatKey, obj[key]);
    }
  }
  return outputString;
};
