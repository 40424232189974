import { EmployeeInformation, Order } from "@amzn/ito-client";
import { Avatar } from "@amzn/stencil-react-components/avatar";
import { AvatarBadgeColor } from "@amzn/stencil-react-components/dist/submodules/avatar/avatarConstants";
import { Col } from "@amzn/stencil-react-components/layout";
import { TableColumn } from "@amzn/stencil-react-components/table";
import { Text } from "@amzn/stencil-react-components/text";
import { ColumnSetting } from "common/types/config-model";
import { COMPLETED } from "common/types/orders-types";
import jp from "jsonpath";
import _, { isArray } from "lodash";
import moment from "moment";
import React from "react";

import { ApproveDenyButtons, ApproveDenyButtonsProps } from "./components/ApproveDeclineButtons/ApproveDeclineButtons";

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbol: "$",
};

/* istanbul ignore next */
const currencyFormatter = (value: any, options: any) => {
  if (typeof value !== "number") value = 0.0;
  options = { ...defaultOptions, ...options };
  value = value.toFixed(options.significantDigits);

  const [currency, decimal] = value.split(".");
  return `${options.symbol} ${currency.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsSeparator)}${
    options.decimalSeparator
  }${decimal}`;
};

export function buildColumnDefinitions(
  columnSettings: ColumnSetting[],
  selectedOption: string,
  { approveOrder, setSelectedOrder, open }: ApproveDenyButtonsProps
): TableColumn<any>[] {
  const columnDefinitions: TableColumn<any>[] = [];
  for (const column of columnSettings) {
    /* istanbul ignore next */
    const cellFunction = (item: any): React.ReactNode => {
      let values: any[] = [];
      let value: any;
      if (isArray(column.selector)) {
        // Multiple values
        value = "";
        values = [];
        for (const selector of column.selector) {
          const val = jp.value(item, selector as string);
          values.push(val);
        }
      } else {
        // Single value
        // Uses jsonpath to get the value
        value = jp.value(item, column.selector as string);
      }

      // Set display based on field type...
      switch (column.type) {
        case "username":
          return buildAmazonianRequesterComponent(values[0] as string, values[1] as EmployeeInformation);
        case "date":
          return buildDateComponent(value as string);
        case "price":
          return (
            <Text fontSize="T200" color="neutral90">
              {currencyFormatter(value, defaultOptions)}
            </Text>
          );
        case "order_name":
          return (
            <Text fontSize="T200" color="neutral90">
              {value}
            </Text>
          );
        case "approve_actions":
          return (
            <ApproveDenyButtons
              key={(value as Order).orderId!}
              approveOrder={approveOrder}
              open={open}
              selectedOrder={value as Order}
              setSelectedOrder={setSelectedOrder}
            ></ApproveDenyButtons>
          );
        default:
          return <span>{value}</span>;
      }
    };

    if (column.type !== "approve_actions" || selectedOption !== COMPLETED) {
      columnDefinitions.push({
        ...column,
        accessor: cellFunction,
      });
    }
  }
  return columnDefinitions;
}

/* istanbul ignore next */
function buildDateComponent(value: string) {
  const date = moment(value).format("MM/DD/YYYY");
  return <span title={value}>{date}</span>;
}

/* istanbul ignore next */
function buildAmazonianRequesterComponent(requestedBy: string, requester: EmployeeInformation) {
  return (
    <Col padding="S300">
      <Avatar
        badgeColor={AvatarBadgeColor.Blue}
        fullName={`${requester.basicInfo?.firstName} ${requester.basicInfo?.lastName}`}
        username={requestedBy}
      />
    </Col>
  );
}
