import { OrderStatus } from "@amzn/ito-client";
import { IconCheckCircleFill, IconClockFill } from "@amzn/stencil-react-components/icons";
import { Row } from "@amzn/stencil-react-components/layout";
import { TableColumn } from "@amzn/stencil-react-components/table";
import { Text } from "@amzn/stencil-react-components/text";
import { ColumnSetting } from "common/types/config-model";
import jp from "jsonpath";
import _ from "lodash";
import moment from "moment";
import React from "react";

export function buildColumnDefinitions(columnSettings: ColumnSetting[]): TableColumn<any>[] {
  const columnDefinitions: TableColumn<any>[] = [];
  for (const column of columnSettings) {
    /* istanbul ignore next */
    const cellFunction = (item: any): React.ReactNode => {
      const value: any = jp.value(item, column.selector as string);

      // Set display based on field type...
      switch (column.type) {
        case "date":
          return buildDateComponent(value.on as string);

        case "order_name":
          return (
            <Text fontSize="T200" color="neutral90" fontWeight={600}>
              {value}
            </Text>
          );

        case "order_status":
          return builOrderStatusComponent(value);
        default:
          return <span>{value}</span>;
      }
    };

    columnDefinitions.push({
      ...column,
      accessor: cellFunction,
    });
  }
  return columnDefinitions;
}

/* istanbul ignore next */
function buildDateComponent(value: string) {
  const date = moment(value).format("MM/DD/YYYY");
  return <span title={value}>{date}</span>;
}

/* istanbul ignore next */
function builOrderStatusComponent(value: string) {
  const iconComponent =
    value == OrderStatus.COMPLETED ? <IconCheckCircleFill color="green70" /> : <IconClockFill color="neutral70" />;
  const label = value == OrderStatus.PENDING_APPROVAL ? "Waiting for approval" : _.capitalize(value);
  return (
    <Row gridGap={8}>
      {iconComponent} {label}
    </Row>
  );
}
