import { Order } from "@amzn/ito-client";
import { IconExternalLink } from "@amzn/stencil-react-components/icons";
import { Col, Flex, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { RowAttributesFunction, Table, TableSpacing } from "@amzn/stencil-react-components/table";
import { Text } from "@amzn/stencil-react-components/text";
import { ColumnSetting } from "common/types/config-model";
import React, { FC } from "react";

import { AssetDetail } from "./AssetDetail";
import myOrdersTableConfig from "./my-orders.table-config.json";
import { buildColumnDefinitions } from "./my-orders-table-utils";
import { useOrdersTable } from "./useOrdersPageTable";

export const MyOrdersTable: FC = () => {
  const columns: ColumnSetting[] = myOrdersTableConfig.columns;
  const currentColumnDefinitions = buildColumnDefinitions(columns);

  const { orders, isLoading } = useOrdersTable();

  /* istanbul ignore next */
  const expandableContent = ({ data, index }: any) => {
    const order: Order = data;
    const content = buildContent(order, index);

    return content;
  };

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const getRowAttributes: RowAttributesFunction = ({ data }: any) => ({
    expandableContent: expandableContent,
    disabled: false,
  });

  if (isLoading) {
    return (
      <Flex flexDirection="column">
        <View alignSelf="center">
          <Spacer height={32} width={32} />
          <Spinner size={SpinnerSize.Medium} showText loadingText="Loading your orders" />
        </View>
      </Flex>
    );
  }

  return (
    <Col gridGap={32} padding={"S400"}>
      <Row justifyContent="flex-end">
        <Link
          href="https://w.amazon.com/bin/view/ITMarketplaceSoftwareOrdering/ITMP_order_support/"
          target="_blank"
          icon={<IconExternalLink title="Opens in a new tab" />}
        >
          Get order support
        </Link>
      </Row>
      <Table
        actionHeader="Detail"
        columns={currentColumnDefinitions}
        data={orders}
        spacing={TableSpacing.Default}
        getRowAttributes={getRowAttributes}
      />
    </Col>
  );
};

/**
 * Builds the content for the expanded row based on the status of the order
 * @param data
 * @param index
 * @returns
 */
/* istanbul ignore next */
const buildContent = (data: any, index: number): React.JSX.Element[] => {
  const order: Order = data;
  let message: string | undefined;
  const innerContent: React.JSX.Element[] = [];
  switch (order.status) {
    case "APPROVED":
      message = "You order has been approved. The IT Marketplace team will complete your order shortly.";
      break;
    case "CANCELLED":
      // TODO get the comment for this order to show the reason it was cancelled
      message = "Your order has been cancelled.";
      break;
    case "COMPLETED":
      // will iterate on all assets, to display info for each
      if (order.assets) {
        for (const asset of order.assets) {
          innerContent.push(<AssetDetail asset={asset} />);
        }
      }
      break;
    case "DENIED":
      message = "Your order has been rejected by your manager.";
      break;
    case "NEW":
    case "PENDING_APPROVAL":
      message = "Your order requires approval. We will notify you once the request gets approved or rejected.";
      break;
    case "PROCESSING":
      message = "Your order is being processed";
      break;
    default:
      break;
  }

  if (message) {
    innerContent.push(
      <Text fontSize="T200" key={`message_${index}`}>
        {message}
      </Text>
    );
  }

  return innerContent;
};
