import "./style.scss";

import { Container, Row, View } from "@amzn/stencil-react-components/layout";
import React, { FC } from "react";

export interface TopBarProps {
  /**
   * Determines if the top bar should go full width or not
   */
  isFullWidth?: boolean;
}

const TopBar: FC<TopBarProps> = ({ children, isFullWidth = true }) => (
  <Container
    className="top-bar"
    isFullWidth={isFullWidth!}
    paddingHorizontal="S700"
    paddingTop="S300"
    paddingBottom="S300"
  >
    <View>
      <Row>{children}</Row>
    </View>
  </Container>
);

export default TopBar;
