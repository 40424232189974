import { useMessageBannersBox } from "common/components/MessageBannersBox/hook";
import { sendSiteSearchMetric } from "common/helpers/analytics-helper";
import { useAppDispatch, useAppSelector } from "common/hooks";
import {
  CatalogicalGetAllItemsResponse,
  CatalogicalItem,
  CatalogicalRequestContent,
} from "common/types/catalogical-type";
import { getString } from "common/uistringlabels/uiStringUtils";
import { useState } from "react";

import {
  fetchCatalogItems,
  selectFacets,
  selectNextToken,
  selectResultItems,
  selectSearchParameters,
  selectTotalResultCount,
  updateFacetsAction,
  updateNextTokenAction,
  updateSearchParametersAction,
} from "./reducer";

export interface UseSearchCatalogicalItemsHookResult {
  isLoading: boolean;
  searchCatalogItems: (query: CatalogicalRequestContent, recetFacets: boolean, shouldSendMetric: boolean) => void;
  searchNext: (query: CatalogicalRequestContent) => void;
  totalCount: number | undefined;
  searchParameters: CatalogicalRequestContent | undefined;
  items: CatalogicalItem[] | undefined;
  facets: { categories: string[]; os: string[] } | undefined;
  nextToken: number | undefined;
  isLoadingNextItems: boolean;
}

export function useSearchCatalogicalItems(): UseSearchCatalogicalItemsHookResult {
  const dispatch = useAppDispatch();
  const totalCount = useAppSelector(selectTotalResultCount);
  const items = useAppSelector(selectResultItems);
  const facets = useAppSelector(selectFacets);
  const searchParameters = useAppSelector(selectSearchParameters);
  const nextToken = useAppSelector(selectNextToken);
  const { addErrorBanner } = useMessageBannersBox();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoadingNextItems, setLoadingNextItems] = useState<boolean>(false);

  /* istanbul ignore next */
  const searchNext = async (query: CatalogicalRequestContent) => {
    setLoadingNextItems(true);
    dispatch(updateSearchParametersAction(query));
    try {
      const result = await dispatch(fetchCatalogItems(query));
      if (result.payload) {
        const nextToken =
          (result.payload as CatalogicalGetAllItemsResponse).pagination.limit +
          (result.payload as CatalogicalGetAllItemsResponse).pagination.offset;
        dispatch(updateNextTokenAction(nextToken!));
      } else {
        addErrorBanner(getString("errors.general"));
      }
    } catch (error) {
      addErrorBanner(getString("errors.general"));
    } finally {
      setLoadingNextItems(false);
    }
  };

  /* istanbul ignore next */
  const searchCatalogItems = async (
    query: CatalogicalRequestContent,
    resetFacets: boolean = false,
    shouldSendMetric: boolean = false
  ) => {
    setLoading(true);
    if (resetFacets) {
      dispatch(updateFacetsAction(undefined));
    }
    dispatch(updateSearchParametersAction(query));
    try {
      const result = await dispatch(fetchCatalogItems(query));
      if (result.payload) {
        const payload = result.payload as CatalogicalGetAllItemsResponse;
        const nextToken = payload.pagination.offset + payload.pagination.limit;
        dispatch(updateNextTokenAction(nextToken!));
        if (shouldSendMetric) {
          sendSiteSearchMetric({
            totalResults: payload.pagination.total!,
            terms: query!.keywords!.split(" "),
          });
        }
      } else {
        addErrorBanner(getString("errors.general"));
      }
    } catch (error) {
      addErrorBanner(getString("errors.general"));
    } finally {
      setLoading(false);
    }
  };

  return {
    isLoading,
    isLoadingNextItems,
    searchCatalogItems,
    searchNext,
    searchParameters,
    totalCount,
    items,
    facets,
    nextToken,
  };
}
