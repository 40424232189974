import { Order } from "@amzn/ito-client";
import { APPROVED, GetOrdersMyDirectsRequest, PENDING_APPROVAL } from "common/types/orders-types";
import { getString } from "common/uistringlabels/uiStringUtils";
import { ITOOrdersService } from "lib/services";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export interface useOrdersApprovalResult {
  orders: Order[];
  isLoading: boolean;
  reloadOrders: () => void;
  fetchDirectOrders: (request: GetOrdersMyDirectsRequest) => void;
  selectedOption: string | undefined;
  handleSelectedOption: (event: React.FormEvent<HTMLElement>, value: string) => void;
  handlePageChange: (page: number) => void;
  numberOfPages: number;
  currentPage: number;
  errorMessage: string | undefined;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  successMessage: string | undefined;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  selectedOrder: Order | undefined;
  setSelectedOrder: Dispatch<SetStateAction<Order | undefined>>;
  approveOrder: (order: Order) => void;
}

/* istanbul ignore next */
export function useOrdersApproval(): useOrdersApprovalResult {
  const defaultPageSize = 5;
  const ito = new ITOOrdersService();
  const [orders, setOrders] = useState<Order[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(PENDING_APPROVAL);
  const [request, setRequest] = useState<GetOrdersMyDirectsRequest>({});
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);

  /* istanbul ignore next */
  const handleSelectedOption = async (event: React.FormEvent<HTMLElement>, value: string) => {
    if (!value) {
      // prevent the event to prevent deselection
      event.preventDefault();
      return;
    }
    const currentRequest: GetOrdersMyDirectsRequest = { ...request, nextToken: "0", filters: { status: [value] } };
    setCurrentPage(1);
    setSelectedOption(value);
    setRequest(currentRequest);
    await fetchDirectOrders(currentRequest);
  };

  /* istanbul ignore next */
  const handlePageChange = async (page: number) => {
    if (page !== currentPage) {
      const nextPage = page - 1;
      const currentRequest: GetOrdersMyDirectsRequest = { ...request, nextToken: nextPage.toString() };
      setCurrentPage(page);
      setRequest(currentRequest);
      await fetchDirectOrders(currentRequest);
    }
  };

  /* istanbul ignore next */
  const reloadOrders = () => {
    const currentRequest: GetOrdersMyDirectsRequest = {
      pageSize: defaultPageSize,
      filters: { status: [PENDING_APPROVAL] },
    };
    setRequest(currentRequest);
    fetchDirectOrders(currentRequest);
  };

  /* istanbul ignore next */
  const approveOrder = async (order: Order) => {
    const { orderId, requester, requested, display } = order;
    const amazonianDisplayName = `${requester?.basicInfo?.firstName} ${requester?.basicInfo?.lastName} (${requested?.by}@)`;
    try {
      const ito = new ITOOrdersService();
      const csrfToken = await ito.GetSecurityToken();
      const updateStatusResult: Order = await ito.UpdateStatus(orderId!, APPROVED, csrfToken);
      if (updateStatusResult) {
        setSuccessMessage(
          getString("myOrdersApproval.approvedSuccessMessage", { name: amazonianDisplayName, software: display })
        );
      } else {
        setErrorMessage(getString("errors.general"));
      }
    } catch (e) {
      setErrorMessage(getString("errors.general"));
    } finally {
      reloadOrders();
    }
  };

  useEffect(() => {
    reloadOrders();
  }, []);

  /* istanbul ignore next */
  const fetchDirectOrders = async (request: GetOrdersMyDirectsRequest) => {
    setLoading(true);
    const { pageSize, filters, nextToken } = request;
    const currentPageSize = pageSize ? defaultPageSize : pageSize!;

    try {
      const result = await ito.GetOrdersDirect({ pageSize: currentPageSize, filters, nextToken });
      if (result) {
        const numberOfPages = Math.ceil(result.totalResults! / currentPageSize);
        setNumberOfPages(numberOfPages === 0 ? 1 : numberOfPages);
        setOrders(result.orders!);
      }
    } catch (e) {
      const err = e as Error;
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    orders,
    isLoading,
    reloadOrders,
    fetchDirectOrders,
    selectedOption,
    handleSelectedOption,
    handlePageChange,
    numberOfPages,
    currentPage,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    selectedOrder,
    setSelectedOrder,
    approveOrder,
  };
}
