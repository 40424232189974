import { Order } from "@amzn/ito-client";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { Table } from "@amzn/stencil-react-components/table";
import { Text } from "@amzn/stencil-react-components/text";
import { TaxonomyIdOrder } from "@amzn/taxonomy-lib";
import React from "react";
import { FC } from "react";

import { PriceText } from "../PriceText";

export interface OrderDetailProps {
  isLoading: boolean;
  orderIdTx?: TaxonomyIdOrder;
  order?: Order;
}

export const OrderDetail: FC<OrderDetailProps> = (props: OrderDetailProps) => {
  if (props.isLoading) {
    return (
      // TODO: show loading icon
      <Spinner size={SpinnerSize.Medium} />
    );
  }

  if (!props.order) {
    return <Text>Order information not found...</Text>;
  }

  // TODO: show detail based in the design
  return (
    <>
      <Text fontSize="T400" margin={["S400", 0, 0, 0]}>
        Order <strong>{props.order.orderReference}</strong> from {props.order.requester?.basicInfo?.firstName}:{" "}
        <strong>{props.order.status}</strong>
      </Text>
      <Table
        columns={[
          {
            accessor: ({ data }) => <Text fontSize="T400">{data.quantity}</Text>,
            header: "Quantity",
          },
          {
            accessor: ({ data }) => <Text fontSize="T400">{data.itemInformation.name}</Text>,
            header: "Item",
          },
          {
            accessor: ({ data }) => <PriceText ammount={data.price.amount} currency={data.price.currency} />,
            header: "Price",
          },
        ]}
        data={props.order.items!}
      />
    </>
  );
};
