import { Status, StatusIndicator, StatusIndicatorSize } from "@amzn/stencil-react-components/status-indicator";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent } from "react";

export interface EtaStatusIndicatorProps {
  etaDays: number;
  hasStock: boolean;
}

export const EtaStatusIndicator: FunctionComponent<EtaStatusIndicatorProps> = ({ etaDays, hasStock }) => {
  if (hasStock === true) {
    return (
      <StatusIndicator
        status={Status.Positive}
        size={StatusIndicatorSize.Large}
        iconAriaHidden={true}
        messageText={getString("eta.statusIndicator.available")}
      />
    );
  }
  const daysRange = `${etaDays}-${etaDays + 2}`;
  return (
    <StatusIndicator
      status={Status.Warning}
      size={StatusIndicatorSize.Large}
      iconAriaHidden={true}
      messageText={getString("eta.statusIndicator.wait", { daysRange })}
    />
  );
};
