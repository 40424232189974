/**
 * Interface for the item values of the catalogical request
 */
export interface CatalogicalItem {
  name: string;
  status?: "sanctioned" | "unsanctioned" | "unavailable";
  subtype: string;
  sku: string;
  price?: {
    amount: number;
    currency: string;
  };
  taxonomyId: string;
  manufacturer: string;
  short_name: string;
  description?: string;
  short_description?: string;
  parent_taxonomy_item_id?: string;
  is_taxonomy_parent?: boolean;
  logo_url?: string;
  recurrence?: string;
  retail_price?: {
    amount: number;
    currency: string;
  };
  order_workflow?: string;
  eta_days?: number;
  restock_threshold?: number;
  has_stock?: boolean;
  catalogicalGenerated?: {
    created?: {
      on: string;
      via: string;
      by: string;
    };
    updated?: {
      on: string;
      via: string;
      by: string;
    };
    isActive?: boolean;
  };
  os?: string[];
  license_activation_instructions?: string;
  license_type?: "single_seat" | "multi_seat";
  resellerInformation?: {
    quote_number: string;
    quote_date: string;
  };
  categories?: string[];
  availability_restrictions?: {
    level: "building" | "country" | "region" | "level" | "organization" | "posix_group";
    values: string[];
  }[];
  references?: {
    website_url: string;
    more_details_url: string;
    order_redirect_url: string;
    product_download_url: string;
  };
}

/**
 * Interface for the answer of the pagination and items information
 */
export interface paginationResponse {
  limit: number;
  offset: number;
  total: number;
}

/**
 * Interface for the answer of specific taxonomy Id request.
 */
export interface CatalogicalGetItemResponse {
  item: CatalogicalItem;
}

/**
 * Interface for the service response that contains an array of catalog request responses and Pagination and items information.
 */
export interface CatalogicalGetAllItemsResponse {
  data: CatalogicalItem[];
  pagination: paginationResponse;
}

/**
 * Interface for the possible query parameters of a catalogical request
 */
export interface CatalogicalRequestContent {
  limit?: number | undefined;
  offset?: number | undefined;
  sortBy?: string | undefined;
  sortDirection?: string | undefined;
  fuzzy?: boolean | undefined;
  keywords?: string | undefined;
  filter?: string | undefined;
}

/**
 * enum of Possible item status
 */
export enum ItemStatus {
  sanctioned = "sanctioned",
  unavailable = "unavailable",
  unsanctioned = "unsanctioned",
}
