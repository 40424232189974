export * from "./reducer";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CatalogItemSimplified, SearchRequestContent } from "common/types";
import { FacetItem, Facets, SearchResponseContent } from "common/types/search-types";
import { produce } from "immer";
import { SearchService } from "lib/services";
import { OldAppState } from "modules/old-app-reducer";

/**
 * Domain for action names
 * Recommended --> domain/action
 */
const actionDomain = "catalogItems";

/**
 * Redux Thunk that handles the get catalog items logic
 */
export const fetchCatalogItems = createAsyncThunk(
  `${actionDomain}/fetch`,
  async (searchParams: SearchRequestContent) => {
    return await SearchService.current.Search(searchParams);
  }
);

/**
 * Type to use in generic export hook
 */
export type FetchCatalogItemsThunk = typeof fetchCatalogItems;

export interface CatalogItemsState {
  /**
   * Catalog items list
   */
  items: CatalogItemSimplified[] | undefined;

  /**
   * Next token to get more results
   */
  nextToken: string | undefined;

  /**
   * Parameter used to filter the catalogItems
   */
  searchParameters?: SearchRequestContent;

  /**
   * Total catalog items that matches the last query
   */
  totalResults: number | undefined;

  /**
   * Facets from the search result
   */
  facets: Facets | undefined;
}

const defaultState: CatalogItemsState = {
  items: [],
  searchParameters: undefined,
  totalResults: 0,
  facets: undefined,
  nextToken: undefined,
};

/**
 * Contains the redux reducers logic to update the state
 */
const catalogItemsSlice = createSlice({
  name: "CatalogItemsSlice",
  initialState: defaultState,
  reducers: {
    updateSearchParametersAction(state, action: PayloadAction<SearchRequestContent>) {
      state.searchParameters = action.payload;
    },
    updateFacetsAction(state, action: PayloadAction<Facets | undefined>) {
      state.facets = action.payload;
    },
    updateNextTokenAction(state, action: PayloadAction<string | undefined>) {
      state.nextToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogItems.fulfilled, (state, action: PayloadAction<SearchResponseContent>) => {
        const nextState = addItems(state, action);
        state.items = nextState.items;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(fetchCatalogItems.rejected, (state) => {
        state.items = [];
        state.totalResults = 0;
        state.facets = undefined;
      });
  },
});

const addItems = (state: CatalogItemsState, action: PayloadAction<SearchResponseContent>) => {
  return produce(state, (draft: CatalogItemsState) => {
    if (action.payload.search?.nextToken && action.payload.search?.nextToken != null) {
      draft.items = [...draft.items!, ...action.payload.items!];
    } else {
      draft.items = action.payload.items;
    }

    return draft;
  });
};

/**
 * Selector for total number of results
 * @param state App state
 * @returns List of catalog items
 */
export const selectNextToken = (state: OldAppState): string | undefined => state.catalogItems.nextToken;

/**
 * Selector for search parameters
 * @param state App state
 * @returns Search parameters
 */
export const selectSearchParameters = (state: OldAppState): SearchRequestContent | undefined =>
  state.catalogItems.searchParameters;

/**
 * Selector for catalog items result
 * @param state App state
 * @returns List of catalog items
 */
export const selectResultItems = (state: OldAppState): CatalogItemSimplified[] | undefined => state.catalogItems.items;

/**
 * Selector for total number of results
 * @param state App state
 * @returns List of catalog items
 */
export const selectTotalResultCount = (state: OldAppState): number | undefined => state.catalogItems.totalResults;

/**
 * Selector for facets result
 * @param state App state
 * @returns List of facets
 */
export const selectFacets = (state: OldAppState): { [key: string]: { [key: string]: FacetItem } } | undefined =>
  state.catalogItems.facets;

export const catalogItemsReducer = catalogItemsSlice.reducer;

export const { updateSearchParametersAction, updateFacetsAction, updateNextTokenAction } = catalogItemsSlice.actions;
