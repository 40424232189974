import "./style.scss";

import { View } from "@amzn/stencil-react-components/layout";
import { PageHeaderLink } from "@amzn/stencil-react-components/page";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent } from "react";

export interface UserProfileProps {
  /**
   * Username
   */
  username: string | null;
}

const UserProfile: FunctionComponent<UserProfileProps> = ({ username }) => (
  <View alignItems="center" height="100%" display="flex" justifyContent="center">
    <PageHeaderLink href="#" aria-label={getString("userProfile.pictureAriaLabel", { username: username })}>
      <span data-testid="username-text" className="header-link username">
        {username}
      </span>
      <div
        data-testid="username-picture"
        className="image-circle"
        style={{ backgroundImage: `url('https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${username}')` }}
      />
    </PageHeaderLink>
  </View>
);

export default UserProfile;
