import { BrowserFeaturesPlugin } from "@snowplow/browser-plugin-browser-features";
import { TimezonePlugin } from "@snowplow/browser-plugin-timezone";
import { newTracker, trackSelfDescribingEvent } from "@snowplow/browser-tracker";
import { getConfigByEnv } from "config";

export interface SiteSearchMetricData extends Record<string, unknown> {
  terms: Array<string>;
  totalResults: number;
}

export const SITE_SEARCH_SCHEMA = "iglu:com.snowplowanalytics.snowplow/site_search/jsonschema/1-0-0";

/**
 * This function will init the Snowplow tracker pointing to the Quitanieves endpoint
 * See https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracker-setup/installing-the-tracker-from-npm/
 */
export const initAnalytics = () => {
  const config = getConfigByEnv();

  if (!config.analytics?.enabled) return;

  // https://docs.snowplow.io/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracker-setup/initialization-options/
  const tracker = newTracker("sp1", config.analytics?.collectorDomain, {
    appId: config.analytics?.appId,
    platform: "web",
    cookieName: "_sp_",
    cookieSameSite: "Lax",
    cookieSecure: true,
    encodeBase64: true,
    respectDoNotTrack: false,
    eventMethod: "post",
    postPath: "/prod",
    stateStorageStrategy: "cookieAndLocalStorage",
    connectionTimeout: 5000,
    anonymousTracking: false,
    contexts: {
      webPage: false,
      session: false,
    },
    retryStatusCodes: [],
    dontRetryStatusCodes: [],
    plugins: [BrowserFeaturesPlugin(), TimezonePlugin()],
  });

  tracker.core.setUseragent(window.navigator.userAgent);
};

export const sendSiteSearchMetric = (data: SiteSearchMetricData) => {
  trackSelfDescribingEvent({
    event: {
      schema: SITE_SEARCH_SCHEMA,
      data,
    },
  });
};
