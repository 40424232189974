import { useResizeObserver } from "@amzn/stencil-react-components/hooks";
import { Col, View } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { SearchResult } from "common/components";
import React, { FC, useLayoutEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { useSearchCatalogicalItems } from "./hook";

export * from "./reducer";

export interface CatalogItemsListProps {
  scrollableTarget: string;
  shouldLoadMore: () => boolean;
  onSearchNext: () => void;
}

const CatalogicalItemsList: FC<CatalogItemsListProps> = (props) => {
  const { items, isLoadingNextItems, totalCount, isLoading } = useSearchCatalogicalItems();

  const { ref, height } = useResizeObserver<HTMLDivElement>();

  const hasMoreItems = () => {
    return items!.length < totalCount!;
  };

  /* istanbul ignore next */
  useLayoutEffect(() => {
    if (!hasMoreItems()) return;
    if (props.shouldLoadMore()) {
      props.onSearchNext();
    }
  }, [height, isLoadingNextItems, isLoading]);

  /* istanbul ignore next */
  const itemsComponents = items!.map((item) => (
    <SearchResult
      key={item.taxonomyId}
      taxonomyId={item.taxonomyId}
      logo_url={item.logo_url || ""}
      name={item.name}
      short_description={item.short_description || ""}
      status={item.status || ""}
    />
  ));

  return (
    <Col ref={ref}>
      <InfiniteScroll
        dataLength={itemsComponents.length}
        next={props.onSearchNext}
        hasMore={hasMoreItems()}
        loader={
          <View padding="S700">
            <Spinner size={SpinnerSize.Medium} />
          </View>
        }
        scrollableTarget={props.scrollableTarget}
      >
        {itemsComponents}
      </InfiniteScroll>
    </Col>
  );
};

export default CatalogicalItemsList;
