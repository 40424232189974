import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { getString } from "common/uistringlabels/uiStringUtils";
import React, { FunctionComponent } from "react";

export interface AutocompleteSearchResultProps {
  logo_url: string;
  name: string;
  sku: string;
  categories: string[];
}

export const AutocompleteSearchResult: FunctionComponent<AutocompleteSearchResultProps> = ({
  logo_url,
  name,
  sku,
  categories,
}) => (
  <Row key={sku} gridGap="S200" alignItems="center" className="search-result-row">
    <Col height="100%">
      <Row>
        <img data-testid="logo-url" height={20} width={20} src={logo_url} alt={`${name} logo`}></img>
      </Row>
    </Col>
    <Col>
      <Row gridGap="S100">
        <Text fontSize="T200">{name}</Text>
      </Row>
      {categories && categories.length > 0 && (
        <Text fontSize="T100">{getString("searchResult.categoryDescription", { description: categories[0] })}</Text>
      )}
    </Col>
  </Row>
);
