import "./style.scss";

import { Link } from "@amzn/stencil-react-components/link";
import { Text } from "@amzn/stencil-react-components/text";
import { ItemTaxonomyId } from "lib/taxonomy/item-taxonomy-id";
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

export interface CatalogItemProps {
  id?: string;
  name?: string;
  shortDescription?: string;
  logoUrl?: string;
  taxonomyId?: string;
  from?: string;
}

/**
 * Reusable component to render thumbnail of product and brief description
 */

export const CatalogItemCard: FunctionComponent<CatalogItemProps> = (props: CatalogItemProps) => {
  const history = useHistory();
  let detailsUrl = "#";

  // Since some catalog items will not have a strain value, parsing the taxonomy
  // could throw an error, handling this with a try/catch in the meantime
  try {
    detailsUrl = `/items/${ItemTaxonomyId.Parse(props.taxonomyId!).getUrlSuffix()}`;
    /* istanbul ignore next */
    if (props.from) {
      detailsUrl += `?from=${props.from}`;
    }
  } catch (error) {
    // TODO: report this error to cloudwatch. (To be resolved by ITMP-344)
    return <span>Incorrect item data.</span>;
  }

  /* istanbul ignore next */
  const onCatalogItemClick = (event: any) => {
    if (detailsUrl !== "#") {
      if (event.ctrlKey) return true; // allows a new tab if ctrl + click

      history.push(detailsUrl);
      event.preventDefault();
    }
  };

  return (
    <Link
      key={`catalogItemCard-${props.id}`}
      href={detailsUrl}
      onClick={onCatalogItemClick}
      textDecoration="none"
      color="neutral90"
      className="catalog-item-card"
    >
      <div className="card-horizontal">
        <div className="img-square-wrapper">
          <img data-details-logo="details-logo" src={props.logoUrl} alt={`${props?.name} logo`} />
        </div>
        <div className="card-body">
          <h4 className="card-title">
            <Text fontSize="T200" fontWeight="bold" className="product-title" color="neutral90">
              {props?.name}
            </Text>
          </h4>
          {props?.shortDescription && (
            <div className="product-short-details">
              <Text data-details-description="details-description" fontSize="T200" className="product-description">
                {props?.shortDescription}
              </Text>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
